import React, {useEffect, useState} from 'react';
import ProjectsList from './ProjectsList';
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";

export default function ExpertDashboard(props) {
    
    return (
        <Container style={{ width: '100vw', maxWidth: '100vw' }}>
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
                <ProjectsList showSnackbar={props.showSnackbar} type="expert" />
            </Box>
        </Container>
    )
}