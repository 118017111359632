import Box from "@mui/material/Box";
import React from "react";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MuiLink from '@mui/material/Link';
import MailTemplatesCard from "./MailTemplatesCard";
import StaffCard from "./StaffCard";
import {
    Outlet,
} from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import {TabPanel, a11yProps} from './TabPanel';
import ProjectsList from "./ProjectsList";
import ProjectsListAll from "./ProjectsListAll";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import axios from 'axios';
import {
    Link,
} from "react-router-dom";



export default function AdminDashboard(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="Разделы панели управления"
                >
                    {/*
                    <Tab label="Сводная таблица" {...a11yProps(0)} />
    */}
                    <Tab label="Назначить экспертов" {...a11yProps(0)} />
                    <Tab label="Одобрить экспертизу" {...a11yProps(1)} />
                    <Tab label="Общий список" {...a11yProps(2)} />
                    {!!!props.curator && <Tab label="Настройки" {...a11yProps(3)} />}
                </Tabs>
            </AppBar>
        <Container  style={{ width: '100vw', maxWidth: '100vw' }}>
            <Box
                sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}
            >
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {/*
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProjectsListAll showSnackbar={props.showSnackbar} />
                </TabPanel>
            */}
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProjectsList showSnackbar={props.showSnackbar}  type="needExpert"/>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <ProjectsList showSnackbar={props.showSnackbar}  type="needCurator"/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <ProjectsList showSnackbar={props.showSnackbar}  type="all" curator={props.curator} field={props.field}/>
                </TabPanel>
                {!!!props.curator && <TabPanel value={value} index={3} dir={theme.direction}>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 240,
                                }}
                            >
                                <MailTemplatesCard />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 240,
                                }}
                            >
                                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                            Выгрузка в Excel
                                        </Typography>
                                        <div>
                                            <MuiLink color="primary" href={"/json/dashboard/excel_export"}>
                                                Скачать
                                            </MuiLink>
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 240,
                                        }}
                                    >
                                        <StaffCard role="expert" />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 240,
                                        }}
                                    >
                                        <StaffCard role="curator" />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 240,
                                        }}
                                    >
                                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                            Настройки
                                        </Typography>
                                        <div>
                                            <MuiLink color="primary" component={Link} to={`/dashboard/settings`}>
                                                Просмотр
                                            </MuiLink>
                                        </div>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 240,
                                        }}
                                    >
                                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                                            Разослать массовое напоминание о загрузке статьй. Участникам 3 этапа, кто не загрузил файл статьи.
                                        </Typography>
                                        <Button variant="contained" onClick={async () => {
                                            try {
                                                let res = await axios.get(`/json/dashboard/massMessage`);
                                                if (!!res.data.status && res.data.status === "ok") {
                                                    props.showSnackbar("Рассылка запущена", "success");
                                                } else {
                                                    props.showSnackbar("Ошибка рассылки", "error");
                                                    console.log("error")
                                                }
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}>Сделать рассылку</Button>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        }

                    </SwipeableViews>
                    <Outlet />

                </Box>
            </Container>
        </>
    );
}
