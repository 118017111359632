import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import { Trans } from "react-i18next";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import axios from 'axios';
import PhoneMask from "./PhoneMask";
import EmailMask from "./EmailMask";
import fields from "./fields";
import statuses from './statuses';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileIcon from '@mui/icons-material/Save';
import FilesList from './FilesList';



export default function UserDashboard(props) {

    const t = props.t;
    const i18n = props.i18n;

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const uchastniki = [...Array(participants).keys()].map((idx) => {
            return {
                ru_lastName: data.get('ru_lastName' + idx),
                ru_firstName: data.get('ru_firstName' + idx),
                ru_middleName: data.get('ru_middleName' + idx),
                lastName: data.get('lastName' + idx),
                firstName: data.get('firstName' + idx),
                birthsday: birthsday[idx],
                workplace: data.get('workplace' + idx),
                position: data.get('position' + idx),
            }
        });

        try {
            let res = await axios.post(`/json/dashboard/saveProject`, {
                participants: uchastniki,
                lastName: data.get('lastName'),
                firstName: data.get('firstName'),
                middleName: data.get('middleName'),
                workplace: data.get('workplace'),
                position: data.get('position'),
                qualification: data.get('qualification'),
                topic: data.get('topic'),
                annotation: data.get('annotation'),
                phone: data.get('phone'),
            });
            if (res.data.status === "ok") {
                props.showSnackbar(t("Успешно"), "success");
            } else {
                props.showSnackbar(t("Ошибка"), "error");
            }
        } catch (err) {
            props.showSnackbar(t("Ошибка"), "error");
            console.log(err);
        }
    };

    const handleChange = (event) => {
        console.log(event.target);
        const new_project = Object.assign({}, project);
        if (/\d+$/.test(event.target.name)) {
            console.log(event.target.name);
            let match = /(\D+)(\d+)$/.exec(event.target.name);
            console.log(match);
            new_project.participants[match[2]][match[1]] = event.target.value;
        } else {
            new_project[event.target.name] = event.target.value;
        }
        console.log(new_project);
        setProject(new_project);
    };

    const [project, setProject] = useState({
        participants: [...Array(3).keys()].map((idx) => {
            return {
                ru_lastName: "",
                ru_firstName: "",
                ru_middleName: "",
                lastName: "",
                firstName: "",
                workplace: "",
                position: "",
            };
        }),
        field: "Лингвистика",
        category: "школьник",
        firstName: "",
        lastName: "",
        middleName: "",
        qualification: "",
        workplace: "",
        position: "",
        topic: "",
        annotation: "",
    });

    const [phone, setPhone] = useState("");
    const [participants, setParticipants] = useState(1);
    const [sum, setSum] = useState(0);
    const [position, setPosition] = useState(1);
    const [email, setEmail] = useState("");
    const [birthsday, setBirthsday] = useState([new Date(), new Date(), new Date(), new Date()]);
    const [criteria, setCriteria] = useState({});

    const load = async (id) => {
        try {
            let res = await axios.get(`/json/dashboard/getProject?rnd=${Math.random()}`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                setProject(res.data.project);
                setParticipants(res.data.project.participants.length);
                setPhone(res.data.project.phone);
                setEmail(res.data.project.email);
                setBirthsday(res.data.project.participants.map((el) => el.birthsday));
                setSum(res.data.sum);
                setPosition(res.data.position);
            } else {
                props.showSnackbar("Ошибка загрузки проекта", "error");
                console.log("error")
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getCriteria = async () => {
        try {
            let res = await axios.get(`/json/dashboard/criteriaList`);
            if (!!res.data.status && res.data.status === "ok") {
                setCriteria(res.data.criteria);
            } else {
                console.log("error")
                props.showSnackbar("Ошибка запроса критериев", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        load();
        getCriteria();
    }, []);


    return (
        <Container component="main" maxWidth="sm">
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language === "ru" ? ruLocale : enLocale}>
                <Box
                    sx={{
                        marginTop: 8,
                        marginBottom: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <PersonIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Личный кабинет участника
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            {[...Array(participants).keys()].map((idx) => (
                                <React.Fragment key={idx}>
                                    <Grid item xs={12} sm={12}>
                                        <FormLabel><Trans>Участник</Trans> {idx + 1}</FormLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            id={`ru_lastName${idx}`}
                                            label={t("Ru Фамилия")}
                                            name={`ru_lastName${idx}`}
                                            autoComplete="family-name"
                                            value={project.participants[idx].ru_lastName}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            autoComplete="given-name"
                                            name={`ru_firstName${idx}`}
                                            required
                                            fullWidth
                                            id={`ru_firstName${idx}`}
                                            label={t("Ru Имя")}
                                            value={project.participants[idx].ru_firstName}
                                            onChange={handleChange}
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            id={`ru_middleName${idx}`}
                                            label={t("Ru Отчество")}
                                            name={`ru_middleName${idx}`}
                                            autoComplete="middle-name"
                                            value={project.participants[idx].ru_middleName}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <DesktopDatePicker
                                            disableFuture
                                            label={t("Дата рождения")}
                                            id={`birthsday${idx}`}
                                            name={`birthsday${idx}`}
                                            onChange={(newValue) => setBirthsday(birthsday.map((el, idx2) => idx2 === idx ? newValue : el))}
                                            value={birthsday[idx]}
                                            required
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </Grid>
                                    {i18n.language === "en" && <>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                autoComplete="given-name"
                                                name={`firstName${idx}`}
                                                required
                                                fullWidth
                                                id={`firstName${idx}`}
                                                label="Name"
                                                autoFocus
                                                value={project.participants[idx].firstName}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                required
                                                fullWidth
                                                id={`lastName${idx}`}
                                                label="Surname"
                                                name={`lastName${idx}`}
                                                autoComplete="family-name"
                                                value={project.participants[idx].lastName}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </>}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id={`workplace${idx}`}
                                            label={t("Место работы / учёбы")}
                                            name={`workplace${idx}`}
                                            autoComplete="workplace"
                                            value={project.participants[idx].workplace}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            id={`position${idx}`}
                                            label={t("Должность / факультет, курс")}
                                            name={`position${idx}`}
                                            autoComplete="position"
                                            value={project.participants[idx].position}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </React.Fragment>
                            ))}
                            {participants < 4 &&
                                <Grid item xs={12} sm={12}>
                                    <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setParticipants(participants + 1)}>
                                        <Trans>Добавить участника</Trans>
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12} sm={12}>
                                <FormLabel><Trans>Сведения о научном руководителе</Trans></FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id={`lastName}`}
                                    label={t("Фамилия")}
                                    name={`lastName`}
                                    autoComplete="family-name"
                                    value={project.lastName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    autoComplete="given-name"
                                    name={`firstName`}
                                    required
                                    fullWidth
                                    id={`firstName`}
                                    label={t("Имя")}
                                    autoFocus
                                    value={project.firstName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id={`middleName`}
                                    label={t("Отчество")}
                                    name={`middleName`}
                                    autoComplete="middle-name"
                                    value={project.middleName}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id={`workplace`}
                                    label={t("Место работы")}
                                    name={`workplace`}
                                    autoComplete="workplace"
                                    value={project.workplace}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id={`position`}
                                    label={t("Должность")}
                                    name={`position`}
                                    autoComplete="position"
                                    value={project.position}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id={`qualification`}
                                    label={t("Ученое степень, ученое звание, почетное звание")}
                                    name={`qualification`}
                                    autoComplete="qualification"
                                    value={project.qualification}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormLabel><Trans>Сведения о проекте</Trans></FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="topic"
                                    label={t("Тема научно-исследовательской работы")}
                                    name="topic"
                                    value={project.topic}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="field-label"><Trans>Название направления</Trans></FormLabel>
                                    <FormControlLabel value={project.field} control={<Radio />} label={t(project.field)} checked/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="category-label"><Trans>Категория участников</Trans></FormLabel>
                                        <FormControlLabel value={project.category} control={<Radio />} label={t(project.category)} checked/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label={t("Контактный телефон")}
                                    autoComplete="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    InputProps={{
                                        inputComponent: PhoneMask
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    disabled
                                    value={email}
                                    InputProps={{
                                        inputComponent: EmailMask
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Сохранить
                        </Button>
                    </Box>
                </Box>

                <Box
                    sx={{
                        marginTop: 8,
                        marginBottom: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <FileIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Файлы
                    </Typography>
                    {<FilesList project={project} addFile={(field, file) => { 
                        const new_project = Object.assign({}, project);
                        new_project[field] = file;
                        setProject(new_project);
                    }} 
                    removeFile={(field) => {
                        const new_project = Object.assign({}, project);
                        new_project[field] = null;
                        setProject(new_project);
                    }}
                    showSnackbar={props.showSnackbar}
                    />}
                </Box>

                <Box
                    sx={{
                        marginTop: 8,
                        marginBottom: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <AccountBalanceIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Результаты. Текущий статус: {!!project && !!project.status && statuses[project.status]}
                    </Typography>
                    {!!project && !!project.status && project.status.includes('Fix') && 
                    <Typography component="h3" variant="h5">
                        Нажмите кнопку "Сохранить" для отправки на повторную экспертизу
                    </Typography>
                    }
                    <Box sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            {!!criteria && ["etap1","etap2", "etap3"].map((etap,idx) => 
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {statuses[etap]}
                                </Typography>
                                    {!!project[etap] && project[etap].map((e, edx) =>
                                        <Accordion key={e.expert}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`${etap}-${project._id}-${edx}-content`}
                                                id={`${etap}-${project._id}-${edx}-header`}
                                            >
                                                <Typography >Протокол оценки {edx+1} этапа №{idx+1}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <FormGroup>
                                                    {!!criteria[etap] && criteria[etap].map((c, idx) =>
                                                        etap == "etap1" ? <FormControlLabel control={<Checkbox  checked={!!e.rating[idx] ? e.rating[idx] == 1 : false} disabled />} label={c} /> :
                                                            <TextField
                                                                sx={{ mb: 1 }}
                                                                value={!!e.rating[idx] ? e.rating[idx] : 0}
                                                                disabled
                                                                label={c}
                                                                type="number"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                    )}
                                                </FormGroup>
                                                <TextField
                                                    id="comment"
                                                    label="Комментарий"
                                                    multiline
                                                    minRows={10}
                                                    fullWidth
                                                    disabled
                                                    value={e.comment}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    )}

                                </Grid>
                            )}
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Сумма баллов
                                </Typography>
                                <Typography >
                                    {sum}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Место в рейтинге
                                </Typography>
                                <Typography >
                                    {!!position?position: '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </LocalizationProvider >
        </Container >
    );
}

