import React, {useEffect, useState, useRef} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import ListItemIcon from "@mui/material/ListItemIcon";
import axios from "axios";

const filesMap = {
    "projectFile": "Описание проекта",
    "articleFile": "Статья"
};

export default function FilesList(props) {

    const uploadAFile = async (files, field) => {
        try {
            let formData = new FormData();
            formData.append("file", files[0]);
            formData.append("id", props.project._id);
            formData.append("field", field);

            let res = await axios.post(`/json/dashboard/uploadFile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Файл добавлен", "success");
                props.addFile(field, res.data.file);
            } else {
                props.showSnackbar("Ошибка добавления файла", "error");
                console.log("error")
            }
        } catch (e) {
            props.showSnackbar("Ошибка добавления файла", "error");
            console.log(e)
        }
    }

    const removeFile = async (field) => {
        try {
            let res = await axios.get(`/json/dashboard/deleteFile?id=${props.project._id}&field=${field}`);
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Файл удалён", "success");
                props.removeFile(field);
            } else {
                props.showSnackbar("Ошибка удаления файла", "error");
                console.log("error")
            }
        }catch (e) {
            console.log(e)
        }
    }

    return (
        <List >
            {["projectFile", "articleFile"].map(e =>
            <ListItem
            >
                <ListItemText
                    primary={filesMap[e]}
                    secondary={!!props.project[e] ? props.project[e].name : "Не загружено"}
                />
                    {!!props.project[e] && Object.keys(props.project[e]).length>0 ? <>
                        {!!!props.expert && <ListItemIcon><IconButton edge="end" aria-label="delete" onClick={() => {
                            removeFile(e);
                        }}
                            color="error"
                        >
                            <DeleteIcon  />
                        </IconButton></ListItemIcon>
                        }
                        <ListItemIcon><IconButton edge="end" aria-label="download" component={"a"} href={`/json/dashboard/getFile?id=${props.project._id}&field=${e}`} download>
                            <DownloadIcon />
                        </IconButton></ListItemIcon>
                    </>
                        :
                        !!!props.expert && <ListItemIcon><IconButton component={"label"} edge="end" aria-label="upload">
                            <UploadIcon />
                                <input type={"file"} hidden accept="*/*" onChange={(env) => uploadAFile(env.target.files, e)} />
                        </IconButton></ListItemIcon>
                }
            </ListItem>
            )}
        </List>
    );
}