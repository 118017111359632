import * as React from 'react';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {
    Link,
} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";

export default function StaffCard(props) {

    const [count, setCount] = useState(0);

    const getCount = async () => {
        try {
            let res = await axios.get(`/json/dashboard/usersCountByRole?role=${props.role}`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                setCount(res.data.count);
            } else {
                console.log("error")
            }
        }catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        getCount();
    },[]);

    return (
        <React.Fragment>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {props.role=="curator" ? "Кураторов" : "Экспертов"}
            </Typography>
            <Typography component="p" variant="h4" sx={{ flex: 1 }}>
                {count}
            </Typography>
            <div>
                <MuiLink color="primary" component={Link} to={`/dashboard/staff/${props.role}`}>
                    Просмотр
                </MuiLink><br/>
                <MuiLink color="secondary" component={Link} to={`/forms/addStaff/${props.role}`}>
                    Добавить
                </MuiLink>
            </div>
        </React.Fragment>
    );
}

