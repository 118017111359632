import React from "react";
import {IMaskInput} from "react-imask";

export default React.forwardRef(function PhoneMask(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={[{ mask:"+0(000)000-00-00" }, { mask:"+00(000)000-00-00" }]}
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});
