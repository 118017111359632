import React from "react";
import Stack from '@mui/material/Stack';          
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Trans } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import SchoolIcon from '@mui/icons-material/School';
import Box from "@mui/material/Box";
import {
    Link,
} from "react-router-dom";
import LanguageIcon from '@mui/icons-material/Language';
import TranslateIcon from '@mui/icons-material/Translate';
import ChatIcon from '@mui/icons-material/Chat';
import BookIcon from '@mui/icons-material/Book';
import List from '@mui/material/List';
import MuiLink from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import MapIcon from '@mui/icons-material/Map';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import CheckIcon from '@mui/icons-material/Check';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import diploms from './diploms';

export default function Home(props) {
  const t = props.t;
  const i18n = props.i18n;
    return (
        <>
        <Container maxWidth="sm" sx={{mt:2}}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
                <Trans>
                    IV Международный конкурс научно-исследовательских работ «Моя интеллект-траектория»
                </Trans>
            </Typography>
            <Typography variant="h7" align="center" color="text.secondary" paragraph>
                <Trans>
                    Конкурс проводится Ульяновским государственным педагогическим университетом им. И.Н. Ульянова, Барановичским государственным университетом, Национальным исследовательским технологическим университетом «МИСИС» и Новосибирским государственным педагогическим университетом.
              </Trans>
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button variant="contained" component={Link} to="/registration"><Trans>Регистрация</Trans></Button>
              <Button variant="outlined" component={MuiLink} href="#info"><Trans>Подробнее</Trans></Button>
            </Stack>
</Container>
<Container>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} >
                                <LanguageIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5" >
                                <Trans>
                                    Лингвистика
                                </Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} >
                                <TranslateIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5" >
                                <Trans>
                                    Переводоведение
                                </Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} >
                                <ChatIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5" >
                                <Trans>
                                    Коммуникативные и культурологические исследования
                                </Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} >
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} >
                                <BookIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5" >
                                <Trans>
                                    Литературоведение
                                </Trans>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} >
                                <SchoolIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5" >
                                <Trans>
                                    Методика обучения иностранному и родному языку
                                </Trans>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            

            <Container id="info">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} style={{textAlign:"justify"}}>
                        <Typography variant="h6">
                            <Trans>
                                Информация о конкурсе
                            </Trans>
                        </Typography>
                        <Divider />
                        <Typography>
                            <Trans>
                                Конкурс направлен на развитие надпрофессиональных навыков учащейся молодежи через вовлечение ее в исследовательскую деятельность и формирование представления о роли науки как социального лифта.
                            </Trans>
                        </Typography>
                        <br/>
                        <Typography>
                            <Trans>
                                В Конкурсе могут принимать участие обучающиеся средних общеобразовательных и средних специальных образовательных учреждений (в категории «Школьник»), студенты высших учебных заведений неязыковых специальностей (в категории «Студент»), студенты высших учебных заведений языковых специальностей (в категории «Студент-лингвист»), магистранты, аспиранты и работники образовательных учреждений в возрасте до 35 лет (в категории «Молодой ученый»).
                            </Trans>
                        </Typography>
                        <br/>
                        <Typography>
                            <Trans>
                                Научно-исследовательская работа, представленная на Конкурс, выполняется участниками индивидуально или в соавторстве с другими участниками по тематике, соответствующей направлениям Конкурса.
                            </Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Paper elevation={1} sx={{ p: 2, bgcolor: 'grey.200' }}>
                            <Typography variant="h6" gutterBottom>
                                <Trans>Образец оформления статьи</Trans>
                            </Typography>
                            <Typography><a href={`/template_${i18n.language}.docx`} download><Trans>Скачать</Trans></a></Typography>
                        </Paper>
                        <br/>
                        <Paper elevation={1} sx={{ p: 2, bgcolor: 'grey.200' }}>
                            <Typography variant="h6" gutterBottom>
                                <Trans>Эксперты</Trans>
                            </Typography>
                            <Typography><a href={`/experts.pdf`} download><Trans>Скачать</Trans></a></Typography>
                        </Paper>
                        <br/>
                        <Paper elevation={1} sx={{ p: 2, bgcolor: 'grey.200' }}>
                            <Typography variant="h6" gutterBottom>
                                <Trans>Список участников допущенных к участию во втором этапе</Trans>
                            </Typography>
                            <Typography><a href={`/stage2participants.pdf`} download><Trans>Скачать</Trans></a></Typography>
                        </Paper>
                        <br/>
                        <Paper elevation={1} sx={{ p: 2, bgcolor: 'grey.200' }}>
                            <Typography variant="h6" gutterBottom>
                                <Trans>Программа VII международной молодежной научно-практической конференции</Trans>
                            </Typography>
                            <Typography><a href={`/pogramm.pdf`} download><Trans>Скачать</Trans></a></Typography>
                        </Paper>
                        <br/>
                        <Paper elevation={1} sx={{ p: 2, bgcolor: 'grey.200' }}>
                            <Typography variant="h6" gutterBottom>
                                <Trans>Список участников допущенных к участию в третьем этапе</Trans>
                            </Typography>
                            <Typography><a href={`/stage3participants.pdf`} download><Trans>Скачать</Trans></a></Typography>
                        </Paper>
                        <br/>
                        <Paper elevation={1} sx={{ p: 2, bgcolor: 'grey.200' }}>
                            <Typography variant="h6" gutterBottom>
                                <Trans>Победители VII Международного конкурса научно-исследовательских работ "Моя интеллект-траектория"</Trans>
                            </Typography>
                            <Typography><a href={`/results.pdf`} download><Trans>Скачать</Trans></a></Typography>
                        </Paper>
                        <br/>
                        <center>
                        <Button variant="contained" size="large" component={Link} to="/registration"><Trans>Регистрация</Trans></Button>
                        </center>
                    </Grid>
                </Grid>
            </Container>

            <Container id="diploms">
                <Box
                    sx={{
                        marginTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" >
                        <Trans>
                            Дипломы / Сертификаты / Благодарности
                        </Trans>
                    </Typography>
                </Box>
                <Grid container spacing={2} justifyContent={"center"}>
                    {diploms.map((cat, idx) =>
                        <Grid item xs={12} sm={4} key={idx}>
                            <Button variant="outlined" size="medium" fullWidth component={Link} to={`/diploms/${cat.cat}`} >
                                <Trans>
                                    {cat.name}
                                </Trans>
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Container>

            <Container sx={{mt:1}}>
                <Box
                    sx={{
                        marginTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" >
                        <Trans>
                            Ключевые даты
                        </Trans>
                    </Typography>
                </Box>
                        <Timeline position="alternate">
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <Trans>
                                    11 декабря 2022 года
                                    </Trans>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot>
                                        <HowToRegIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        <Trans>Окончание регистрации</Trans>
                                    </Typography>
                                    <Typography><Trans>для участия в Конкурсе и приема описаний проектов исследовательских работ</Trans></Typography>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <Trans>
                                        15 января 2023 года
                                    </Trans>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot color="primary">
                                        <CheckIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        <Trans>Подведение итогов первого этапа</Trans>
                                    </Typography>
                                    <Typography>
                                        <Trans>Подтверждение допуска к участию во втором и третьем этапах Конкурса</Trans><br/>
                                        <MuiLink href="/stage2participants.pdf"><Trans>Список участников допущенных к участию во втором этапе</Trans></MuiLink>
                            </Typography>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <Trans>
                                    Апрель 2023 года
                                    </Trans>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot>
                                        <LooksTwoIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        <Trans>Второй этап Конкурса</Trans>
                                    </Typography>
                                    <Typography><Trans>в форме онлайн-презентаций устных докладов по результатам исследования. Время выступления – не более 8 минут. Участники должны осветить современное состояние исследуемой проблемы, четко и подробно описать методы и результаты собственного исследования, убедительно представить выводы, продемонстрировать владение приемами взаимодействия с аудиторией и ведения научной дискуссии. Возможна демонстрация слайдов, иллюстрирующих высказываемые положения.</Trans><br/>
                                        <MuiLink href="/stage3participants.pdf"><Trans>Список участников допущенных к участию в третьем этапе</Trans></MuiLink>
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <Trans>
                                        21 мая 2023 года
                                    </Trans>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot color="primary">
                                        <MoveToInboxIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        <Trans>Окончание приема научных статей</Trans>
                                    </Typography>
                                    <Typography><Trans>по результатам проведенного исследования на русском, английском, французском, немецком, испанском или китайском языке для участия в третьем этапе конкурса Статья должна содержать освещение современного состояния исследуемой проблемы, четкое и подробное описание использованных методов и полученных результатов, выводы по результатам работы.</Trans></Typography>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    align="right"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    <Trans>
                                    25 июня 2023 года
                                    </Trans>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineDot color="secondary">
                                        <EmojiEventsIcon />
                                    </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="h6" component="span">
                                        <Trans>Подведение итогов конкурса</Trans>
                                    </Typography>
                                    <Typography><Trans>путем суммирования баллов участников, полученных на втором этапе Конкурса (в результате оценки устного доклада), и третьем этапе Конкурса (в результате оценки научных статей).</Trans><br/>
                                        {/*<MuiLink href="/results.docx"><Trans>Победители IV Международного конкурса научно-исследовательских работ "Моя интеллект-траектория"</Trans></MuiLink>*/}
                                    </Typography>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
            </Container>
            <Container sx={{mt:2}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} style={{textAlign:"justify"}}>
                        <Typography variant="h6">
                            <Trans>
                                Требования к описанию проекта
                            </Trans>
                        </Typography>
                        <Divider />
                        <Typography>
                            <Trans>
                                Описание проекта выполняется на русском или английском языках в произвольной форме (объем 1-2 страницы, шрифт Times New Roman, размер - 12, интервал 1,5). Обязательными элементами описания проекта являются:
                            </Trans>
                        </Typography>
                        <ul>
                            <li><Trans>Формулировка темы работы</Trans></li>
                            <li><Trans>Формулировка научной проблемы, на решение которой направлена работы (предпочтительно в форме исследовательского вопроса)</Trans></li>
                            <li><Trans>Формулировка гипотезы, лежащей в основе работы (предполагаемого ответа на заданный вопрос)</Trans></li>
                            <li><Trans>Описание актуальности работы (со ссылками на авторов исследований, проведенных в последние 10-15 лет в рассматриваемой области науки)</Trans></li>
                            <li><Trans>Описание новизны работы</Trans></li>
                            <li><Trans>Указание цели работы</Trans></li>
                            <li><Trans>Описание материала исследования</Trans></li>
                            <li><Trans>Описание методики исследования (пошаговое)</Trans></li>
                            <li><Trans>Библиографический список</Trans></li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">
                            <Trans>
                                Требования к оформлению научной статьи
                            </Trans>
                        </Typography>
                        <Divider />
                        <Typography>
                            <Trans>
                                Электронный вариант статьи в отдельном файле в формате RTF или Документ Word 1997-2003, например, Иванов_Ульяновск_статья. Объем текста – не менее 4 страниц, не более 9 страниц.
                            </Trans>
                        </Typography>
                        <ol>
                            <li><i><Trans>Параметры страницы</Trans></i><Trans>: текст в формате RTF или Документ Word 1997-2003, поля: слева, справа, снизу и сверху – 2 см. Страницы не проставлять.</Trans></li>
                            <li><i><Trans>Шрифт</Trans></i><Trans>: Times New Roman, размер – 14, междустрочный интервал – 1,5, абзац (первая строка) – 1,25; отступ: слева, справа – 0; интервалы: до, после – 0. Вручную (с помощью пробела) абзацы не оформлять! Выравнивание текста – по ширине.</Trans></li>
                            <li><i><Trans>Оформление текста</Trans></i><Trans>: Фамилия и инициалы автора, организация, город, страна указываются перед заглавием статьи полужирным шрифтом, выравнивание текста - справа. Заглавие статьи прописными буквами полужирным шрифтом, выравнивание текста - по центру. Под заглавием через 1 интервал - краткая аннотация статьи (3-8 строк) и ключевые слова (не более 6 слов или словосочетаний). Все данные представляются сначала на русском языке, потом – на английском.</Trans></li>
                            <li><i><Trans>Оформление списка источников</Trans></i><Trans>: Список источников оформляется в порядке цитирования в тексте и нумеруется. В список источников включают записи только на ресурсы, которые упомянуты или цитируются в основном тексте статьи с обязательным указанием места и названия издательства, общего количества страниц. Шрифт Times New Roman, размер – 12, междустрочный интервал – 1, абзац (первая строка) – 0. Для связи с текстом статьи порядковый номер библиографической записи и страницу указывают в квадратных скобках: [3, c. 82], автоматических сносок не делать.</Trans></li>
                        </ol>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Box
                    sx={{
                        marginTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} >
                        <AlternateEmailIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" >
                        <Trans>
                            Контакты
                        </Trans>
                    </Typography>
                    <a href={"mailto:mit@ulspu.ru"}>mit@ulspu.ru</a>
                </Box>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar src={"/logo192.png"} />
                            <Typography component="h3" variant="h5" >
                                <Trans>
                                    Кафедра английского языка УлГПУ им. И.Н. Ульянова
                                </Trans>
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <MapIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Российская Федерация, г. Ульяновск, пл. Ленина, д. 4/5")} />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"8 8422-44-15-92"} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar src={"/bargu.png"} />
                            <Typography component="h3" variant="h5" >
                                <Trans>
                                    Кафедра профессиональной иноязычной подготовки БарГУ
                                </Trans>
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <MapIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Республика Беларусь, Брестская область, г. Барановичи, ул. Парковая, д. 62")} />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"+375 163-68-01-70"} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar src={"/logo_misis.png"} />
                            <Typography component="h3" variant="h5" >
                                <Trans>
                                    Кафедра иностранных языков и коммуникативных технологий НИТУ "МИСИС"
                                </Trans>
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <MapIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Российская Федерация, г. Москва, Ленинский пр-кт, д. 4, стр. 1 Б-1035")} />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"+7 916 927-73-05"} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar src={"/ngpu.png"} />
                            <Typography component="h3" variant="h5" >
                                <Trans>
                                    Кафедра лингвистики и теории перевода НГПУ
                                </Trans>
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <MapIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Российская Федерация, г. Новосибирск, ул. Вилюйская, д. 28")} />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"+7 383-244-13-86"} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                marginTop: 4,
                                marginBottom: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Avatar src={"/margu.png"} />
                            <Typography component="h3" variant="h5" >
                                <Trans>
                                    Кафедра английской филологии МарГУ
                                </Trans>
                            </Typography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <MapIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Российская Федерация, Республика Марий Эл, г. Йошкар-Ола, ул. Кремлевская, 44")} />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <PhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"+7 8362-68-79-90"} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}