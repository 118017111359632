import React, {useEffect, useState} from "react";
import {
     useParams
} from "react-router-dom";
import Table from "./Table";
import axios from "axios";

export default function StaffList(props) {
    useEffect(() => {
        getList();
    },[]);

    let params = useParams();

    const getList = async () => {
        try {
            let res = await axios.get(`/json/dashboard/usersListByRole?role=${params.role}&rnd=${Math.random()}`);
            if (!!res.data.status && res.data.status === "ok") {
                setList(res.data.rows);
                return res.data.rows;
            } else {
                console.log("error")
                props.showSnackbar("Ошибка запроса списка", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'email',
                accessor: 'username',
            },
            {
                Header: 'ФИО',
                accessor: 'fio',
            },
            {
                Header: 'Телефон',
                accessor: 'phone',
            },
            {
                Header: 'Город',
                accessor: 'city',
            },
            {
                Header: 'Место работы',
                accessor: 'workplace',
            },
            {
                Header: 'Должность',
                accessor: 'position',
            },
        ],
        []
    )

    const [list, setList] = useState([]);
    const data = React.useMemo(() => list, [list]);

    return (
        <Table columns={columns} data={data} />
    )
}

