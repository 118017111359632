import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "Моя интеллект-траектория":
            "My Intellect Trajectory",
          "УлГПУ им. И.Н. Ульянова": "Ulyanovsk State University of Education",
          "IV Международный конкурс научно-исследовательских работ «Моя интеллект-траектория»": "V International Contest in Research for Youth My Intellect Trajectory",
          "Конкурс проводится Ульяновским государственным педагогическим университетом им. И.Н. Ульянова, Барановичским государственным университетом, Национальным исследовательским технологическим университетом «МИСИС» и Новосибирским государственным педагогическим университетом.": "The contest is organized by Ulyanovsk State University of Education, Baranovichi State University, University of Science and Technology MISIS, Novosibirsk State Pedagogical University, Mari State University.",
          "Регистрация": "Registration",
          "Подробнее": "Details",
          "Лингвистика": "Linguistics",
          "Переводоведение": "Translation studies",
          "Коммуникативные и культурологические исследования": "Communication and culture studies",
          "Литературоведение": "Philology",
          "Методика обучения иностранному и родному языку": "Language teaching",
          "Контакты": "Contacts",
          "Кафедра английского языка УлГПУ им. И.Н. Ульянова": "Ulyanovsk State University of Education",
          "Российская Федерация, г. Ульяновск, пл. Ленина, д. 4/5": "Российская Федерация, г. Ульяновск, пл. Ленина, д. 4/5",
          "Кафедра профессиональной иноязычной подготовки БарГУ": "Baranovichi State University",
          "Республика Беларусь, Брестская область, г. Барановичи, ул. Парковая, д. 62": "Республика Беларусь, Брестская область, г. Барановичи, ул. Парковая, д. 62",
          "Кафедра иностранных языков и коммуникативных технологий НИТУ \"МИСИС\"": "University of Science and Technology MISIS",
          "Российская Федерация, г. Москва, Ленинский пр-кт, д. 4, стр. 1 Б-1035": "Российская Федерация, г. Москва, Ленинский пр-кт, д. 4, стр. 1 Б-1035",
          "Кафедра лингвистики и теории перевода НГПУ": "Novosibirsk State Pedagogical University",
          "Российская Федерация, г. Новосибирск, ул. Вилюйская, д. 28": "Российская Федерация, г. Новосибирск, ул. Вилюйская, д. 28",
          "Информация о конкурсе": "Contest details",
          "Конкурс направлен на развитие надпрофессиональных навыков учащейся молодежи через вовлечение ее в исследовательскую деятельность и формирование представления о роли науки как социального лифта.": "The contest gives young researchers an opportunity to improve their soft skills, meet others with similar abilities and interests, get guidance from experts in philology and education.",
          "В Конкурсе могут принимать участие обучающиеся средних общеобразовательных и средних специальных образовательных учреждений (в категории «Школьник»), студенты высших учебных заведений неязыковых специальностей (в категории «Студент»), студенты высших учебных заведений языковых специальностей (в категории «Студент-лингвист»), магистранты, аспиранты и работники образовательных учреждений в возрасте до 35 лет (в категории «Молодой ученый»).": "Participants will submit projects in the following fields: linguistics; translation studies; communication and culture studies; philology; language teaching.",
          "Научно-исследовательская работа, представленная на Конкурс, выполняется участниками индивидуально или в соавторстве с другими участниками по тематике, соответствующей направлениям Конкурса.": "The IV International Contest in Research for Youth “My Intellect Trajectory” accepts project entries from high school students, undergraduate (BA or MA) and graduate students, as well as university teachers aged between 14 and 35 at the date of the contest. Projects can be by individuals or teams. Submissions in Chinese, English, French, German, Russian and Spanish are accepted.",
          "Образец оформления статьи": "Paper template",
          "Скачать": "Download",
          "Ключевые даты": "Important dates",
          "11 декабря 2022 года": "December 10, 2023",
          "Окончание регистрации": "Deadline for registration",
          "для участия в Конкурсе и приема описаний проектов исследовательских работ": "and submitting the project description",
          "15 января 2023 года": "January 21, 2024 ",
          "Подведение итогов первого этапа": "Deadline for reviewing the project descriptions",
          "Подтверждение допуска к участию во втором и третьем этапах Конкурса": "Judges will select finalists based on a review of project descriptions. These finalists will advance to Stage II and Stage III of the contest.",
          "Апрель 2023 года": "April 18-20, 2024",
          "Второй этап Конкурса": "live presentation of the research with a virtual option",
          "в форме онлайн-презентаций устных докладов по результатам исследования. Время выступления – не более 8 минут. Участники должны осветить современное состояние исследуемой проблемы, четко и подробно описать методы и результаты собственного исследования, убедительно представить выводы, продемонстрировать владение приемами взаимодействия с аудиторией и ведения научной дискуссии. Возможна демонстрация слайдов, иллюстрирующих высказываемые положения.": "Contestants give a short talk (up to 8 minutes) on their research. They will also be required to answer questions from the members of the jury.",
          "21 мая 2023 года": "May 19, 2024",
          "Окончание приема научных статей": "deadline for submitting written projects",
          "по результатам проведенного исследования на русском, английском, французском, немецком, испанском или китайском языке для участия в третьем этапе конкурса Статья должна содержать освещение современного состояния исследуемой проблемы, четкое и подробное описание использованных методов и полученных результатов, выводы по результатам работы.": "Contestants submit a paper based on the research project",
          "25 июня 2023 года": "June 23, 2024",
          "Подведение итогов конкурса": "Winner announcement ",
          "путем суммирования баллов участников, полученных на втором этапе Конкурса (в результате оценки устного доклада), и третьем этапе Конкурса (в результате оценки научных статей).": "Expert panel of judges will select up to three winning projects in each of the five fields.",
          "Требования к описанию проекта": "Abstract Submission Guidelines",
          "Описание проекта выполняется на русском или английском языках в произвольной форме (объем 1-2 страницы, шрифт Times New Roman, размер - 12, интервал 1,5). Обязательными элементами описания проекта являются:": "Abstracts should be 2 pages A4 long (maximum), font for the text: Times New Roman, size 12 points, space between lines 1.5.. Abstracts should clearly present",
          "Формулировка темы работы": "the title of the paper",
          "Формулировка научной проблемы, на решение которой направлена работы (предпочтительно в форме исследовательского вопроса)": "the research question",
          "Формулировка гипотезы, лежащей в основе работы (предполагаемого ответа на заданный вопрос)": "the hypothesis",
          "Описание актуальности работы (со ссылками на авторов исследований, проведенных в последние 10-15 лет в рассматриваемой области науки)": "the state of the art",
          "Описание новизны работы": "the research gap ",
          "Указание цели работы": "the objective",
          "Описание материала исследования": "materials and methods",
          "Описание методики исследования (пошаговое)": "",
          "Библиографический список": "bibliography",
          "Требования к оформлению научной статьи": "Paper Submission Guidelines",
          "Электронный вариант статьи в отдельном файле в формате RTF или Документ Word 1997-2003, например, Иванов_Ульяновск_статья. Объем текста – не менее 4 страниц, не более 9 страниц.": "Электронный вариант статьи в отдельном файле в формате RTF или Документ Word 1997-2003, например, Иванов_Ульяновск_статья. Объем текста – не менее 4 страниц, не более 9 страниц.",
          "Параметры страницы": "Papers should be",
          ": текст в формате RTF или Документ Word 1997-2003, поля: слева, справа, снизу и сверху – 2 см. Страницы не проставлять.": " 4-9 pages A4 long and clearly present a research question/objective, literature review, methodological approach, results, conclusions and sources.",
          "Шрифт": "Please send",
          ": Times New Roman, размер – 14, междустрочный интервал – 1,5, абзац (первая строка) – 1,25; отступ: слева, справа – 0; интервалы: до, после – 0. Вручную (с помощью пробела) абзацы не оформлять! Выравнивание текста – по ширине.": " the articles in the format .doc (Word 2003) or RTF with the following layout: all margins 2.0 cm, space between lines: 1.5, font for the text: Times New Roman, size 14 points, block alignment, indent the first paragraph line (1.25cm).",
          "Оформление текста": "The first line",
          ": Фамилия и инициалы автора, организация, город, страна указываются перед заглавием статьи полужирным шрифтом, выравнивание текста - справа. Заглавие статьи прописными буквами полужирным шрифтом, выравнивание текста - по центру. Под заглавием через 1 интервал - краткая аннотация статьи (3-8 строк) и ключевые слова (не более 6 слов или словосочетаний). Все данные представляются сначала на русском языке, потом – на английском.": " will have the name of the author, affiliation, the city and the country in bold, justified right. The title of the article in bold (upper-case font, centre alignment) will follow.  There is to be a short abstract of the article (around 100 words) and key words in italics in the language of the paper and in the English language. Please, do not insert numbers of pages; do not write chapters and subchapters.",
          "Оформление списка источников": "Number reference",
          ": Список источников оформляется в порядке цитирования в тексте и нумеруется. В список источников включают записи только на ресурсы, которые упомянуты или цитируются в основном тексте статьи с обязательным указанием места и названия издательства, общего количества страниц. Шрифт Times New Roman, размер – 12, междустрочный интервал – 1, абзац (первая строка) – 0. Для связи с текстом статьи порядковый номер библиографической записи и страницу указывают в квадратных скобках: [3, c. 82], автоматических сносок не делать.": " list entries in the order they are cited in the text. Each source cited in the paper must appear in the reference list; likewise, each entry in the reference list must be cited in the text. Space between lines of the list of references: 1. Font for the list of references: Times New Roman, size 12 points, block alignment, do not indent the first line. In-text citation: the number of the source should appear in the text, followed by the page number: [3, p. 82].",
          "Форма регистрации": "Registration form",
          "Участник": "Participant",
          "Фамилия": "Surname",
          "Имя": "Name",
          "Отчество": "Middlename",
          "Ru Фамилия": "Russian surname",
          "Ru Имя": "Russian name",
          "Ru Отчество": "Russian middlename",
          "Дата рождения": "Date of birth",
          "Добавить участника": "Add participant",
          "Место работы / учёбы": "Affiliation",
          "Должность / факультет, курс": "Position/ Year of study, department",
          "Сведения о научном руководителе": "Academic supervisor",
          "Место работы": "Affiliation",
          "Должность": "Position",
          "Ученое степень, ученое звание, почетное звание": "Academic degree and rank",
          "Сведения о проекте": "Project",
          "Тема научно-исследовательской работы": "Title of the paper",
          "Название направления": "Subfield",
          "Категория участников": "Category",
          "школьник": "high school student",
          "студент": "undergraduate student",
          "студент-лингвист": "undergraduate language major",
          "молодой ученый": "researcher",
          "Контактный телефон": "Phone number",
          "Настоящим я даю согласие на обработку моих персональных данных УлГПУ им. И.Н. Ульянова (далее - Университет), в том числе на совершение Администрацией действий, предусмотренных п. 3 ст. 3 Федерального закона от 27.07.2006 года №152-ФЗ \"О персональных данных\"": "Hereby I grant my consent on processing my personal data to Ulyanovsk State University of Education including the actions in accordance with paragraph 3 of article 3 of Federal law \"On personal data\" as of July, 27th 2006, No 152-FZ.",
          "Файл загружен": "File is uploaded",
          "Ошибка добавления файла": "File upload error",
          "Статья успешно загружена": "Paper is uploaded",
          "Загрузка статьи": "Paper upload",
          "Файл прикреплён": "File is attached",
          "Прикрепить файл": "Attach a file",
          "Загрузить": "Upload",
          "Вы успешно вошли в систему": "You have logged in",
          "Авторизация": "Authorisation",
          "Пароль": "Password",
          "Запомнить меня": "Remember me",
          "Не верный email или пароль": "Wrong email or password",
          "Войти": "Login",
          "Вы успешно зарегистрировались": "You have registered",
          "Ошибка регистрации": "Registration error",
          "Аннотация": "Project description",
          "Эксперты": "Experts",
          "Список участников допущенных к участию во втором этапе": "Participants list admitted to participate in the second stage",
          "Список участников допущенных к участию в третьем этапе": "Participants list admitted to participate in the third stage",
          "Победители VII Международного конкурса научно-исследовательских работ \"Моя интеллект-траектория\"":"Winners of the VII International Research Competition \"My intellect-trajectory\"",
          "Кафедра английской филологии МарГУ": "Mari State University",
          "Российская Федерация, Республика Марий Эл, г. Йошкар-Ола, ул. Кремлевская, 44": "Российская Федерация, Республика Марий Эл, г. Йошкар-Ола, ул. Кремлевская, 44",
          "Личный кабинет": "Dashboard",
          "Выход": "Logout",
          "Вход": "Login",
          "Программа VII международной молодежной научно-практической конференции": "Program of the VII International Youth Scientific and Practical Conference",
          "Дипломы / Сертификаты / Благодарности": "Diplomas / Certificates / Gratitude",
          "Дипломы лауреатов": "Laureate diplomas",
          "Сертификаты участников": "Certificates of participants",
          "Дипломы победителей": "Winners' diplomas",
          "Благодарности руководителям": "Thanks to the supervisors",
        }
      },
      ru: {
        translations: {
          "Моя интеллект-траектория":
            "Моя интеллект-траектория",
            "УлГПУ им. И.Н. Ульянова": "УлГПУ им. И.Н. Ульянова",
            "IV Международный конкурс научно-исследовательских работ «Моя интеллект-траектория»": "V Международный конкурс научно-исследовательских работ «Моя интеллект-траектория»",
          "Конкурс проводится Ульяновским государственным педагогическим университетом им. И.Н. Ульянова, Барановичским государственным университетом, Национальным исследовательским технологическим университетом «МИСИС» и Новосибирским государственным педагогическим университетом.": "Конкурс проводится Ульяновским государственным педагогическим университетом им. И.Н. Ульянова, Барановичским государственным университетом, Национальным исследовательским технологическим университетом «МИСИС», Новосибирским государственным педагогическим университетом, Марийским государственным университетом .",
          "Регистрация": "Регистрация",
          "Подробнее": "Подробнее",
          "Лингвистика": "Лингвистика",
          "Переводоведение": "Переводоведение",
          "Коммуникативные и культурологические исследования": "Коммуникативные и культурологические исследования",
          "Литературоведение": "Литературоведение",
          "Методика обучения иностранному и родному языку": "Методика обучения иностранному и родному языку",
          "Контакты": "Контакты",
          "Кафедра английского языка УлГПУ им. И.Н. Ульянова": "Кафедра английского языка УлГПУ им. И.Н. Ульянова",
          "Российская Федерация, г. Ульяновск, пл. Ленина, д. 4/5": "Российская Федерация, г. Ульяновск, пл. Ленина, д. 4/5",
          "Кафедра профессиональной иноязычной подготовки БарГУ": "Кафедра профессиональной иноязычной подготовки БарГУ",
          "Республика Беларусь, Брестская область, г. Барановичи, ул. Парковая, д. 62": "Республика Беларусь, Брестская область, г. Барановичи, ул. Парковая, д. 62",
          "Кафедра иностранных языков и коммуникативных технологий НИТУ \"МИСИС\"": "Кафедра иностранных языков и коммуникативных технологий НИТУ \"МИСИС\"",
          "Российская Федерация, г. Москва, Ленинский пр-кт, д. 4, стр. 1 Б-1035": "Российская Федерация, г. Москва, Ленинский пр-кт, д. 4, стр. 1 Б-1035",
          "Кафедра лингвистики и теории перевода НГПУ": "Кафедра лингвистики и теории перевода НГПУ",
          "Российская Федерация, г. Новосибирск, ул. Вилюйская, д. 28": "Российская Федерация, г. Новосибирск, ул. Вилюйская, д. 28",
          "Информация о конкурсе": "Информация о конкурсе",
          "Конкурс направлен на развитие надпрофессиональных навыков учащейся молодежи через вовлечение ее в исследовательскую деятельность и формирование представления о роли науки как социального лифта.": "Конкурс направлен на развитие надпрофессиональных навыков учащейся молодежи через вовлечение ее в исследовательскую деятельность и формирование представления о роли науки как социального лифта.",
          "В Конкурсе могут принимать участие обучающиеся средних общеобразовательных и средних специальных образовательных учреждений (в категории «Школьник»), студенты высших учебных заведений неязыковых специальностей (в категории «Студент»), студенты высших учебных заведений языковых специальностей (в категории «Студент-лингвист»), магистранты, аспиранты и работники образовательных учреждений в возрасте до 35 лет (в категории «Молодой ученый»).": "В Конкурсе могут принимать участие обучающиеся средних общеобразовательных и средних специальных образовательных учреждений (в категории «Школьник»), студенты высших учебных заведений неязыковых специальностей (в категории «Студент»), студенты высших учебных заведений языковых специальностей (в категории «Студент-лингвист»), магистранты, аспиранты и работники образовательных учреждений в возрасте до 35 лет (в категории «Молодой ученый»).",
          "Научно-исследовательская работа, представленная на Конкурс, выполняется участниками индивидуально или в соавторстве с другими участниками по тематике, соответствующей направлениям Конкурса.": "Научно-исследовательская работа, представленная на Конкурс, выполняется участниками индивидуально или в соавторстве с другими участниками по тематике, соответствующей направлениям Конкурса.",
          "Образец оформления статьи": "Образец оформления статьи",
          "Скачать": "Скачать",
          "Ключевые даты": "Ключевые даты",
          "11 декабря 2022 года": "10 декабря 2023 года",
          "Окончание регистрации": "Окончание регистрации",
          "для участия в Конкурсе и приема описаний проектов исследовательских работ": "для участия в Конкурсе и приема описаний проектов исследовательских работ",
          "15 января 2023 года": "21 января 2024 года",
          "Подведение итогов первого этапа": "Подведение итогов первого этапа",
          "Подтверждение допуска к участию во втором и третьем этапах Конкурса": "Подтверждение допуска к участию во втором и третьем этапах Конкурса",
          "Апрель 2023 года": "18-20 апреля 2024 года",
          "Второй этап Конкурса": "Второй этап Конкурса",
          "в форме онлайн-презентаций устных докладов по результатам исследования. Время выступления – не более 8 минут. Участники должны осветить современное состояние исследуемой проблемы, четко и подробно описать методы и результаты собственного исследования, убедительно представить выводы, продемонстрировать владение приемами взаимодействия с аудиторией и ведения научной дискуссии. Возможна демонстрация слайдов, иллюстрирующих высказываемые положения.": "в форме онлайн-презентаций устных докладов по результатам исследования. Время выступления – не более 8 минут. Участники должны осветить современное состояние исследуемой проблемы, четко и подробно описать методы и результаты собственного исследования, убедительно представить выводы, продемонстрировать владение приемами взаимодействия с аудиторией и ведения научной дискуссии. Возможна демонстрация слайдов, иллюстрирующих высказываемые положения.",
          "21 мая 2023 года": "19 мая 2024 года",
          "Окончание приема научных статей": "Окончание приема научных статей",
          "по результатам проведенного исследования на русском, английском, французском, немецком, испанском или китайском языке для участия в третьем этапе конкурса Статья должна содержать освещение современного состояния исследуемой проблемы, четкое и подробное описание использованных методов и полученных результатов, выводы по результатам работы.": "по результатам проведенного исследования на русском, английском, французском, немецком, испанском или китайском языке для участия в третьем этапе конкурса Статья должна содержать освещение современного состояния исследуемой проблемы, четкое и подробное описание использованных методов и полученных результатов, выводы по результатам работы.",
          "25 июня 2023 года": "23 июня 2024 года",
          "Подведение итогов конкурса": "Подведение итогов конкурса",
          "путем суммирования баллов участников, полученных на втором этапе Конкурса (в результате оценки устного доклада), и третьем этапе Конкурса (в результате оценки научных статей).": "путем суммирования баллов участников, полученных на втором этапе Конкурса (в результате оценки устного доклада), и третьем этапе Конкурса (в результате оценки научных статей).",
          "Требования к описанию проекта": "Требования к описанию проекта",
          "Описание проекта выполняется на русском или английском языках в произвольной форме (объем 1-2 страницы, шрифт Times New Roman, размер - 12, интервал 1,5). Обязательными элементами описания проекта являются:": "Описание проекта выполняется на русском или английском языках в произвольной форме (объем 1-2 страницы, шрифт Times New Roman, размер - 12, интервал 1,5). Обязательными элементами описания проекта являются:",
          "Формулировка темы работы": "Формулировка темы работы",
          "Формулировка научной проблемы, на решение которой направлена работы (предпочтительно в форме исследовательского вопроса)": "Формулировка научной проблемы, на решение которой направлена работы (предпочтительно в форме исследовательского вопроса)",
          "Формулировка гипотезы, лежащей в основе работы (предполагаемого ответа на заданный вопрос)": "Формулировка гипотезы, лежащей в основе работы (предполагаемого ответа на заданный вопрос)",
          "Описание актуальности работы (со ссылками на авторов исследований, проведенных в последние 10-15 лет в рассматриваемой области науки)": "Описание актуальности работы (со ссылками на авторов исследований, проведенных в последние 10-15 лет в рассматриваемой области науки)",
          "Описание новизны работы": "Описание новизны работы",
          "Указание цели работы": "Указание цели работы",
          "Описание материала исследования": "Описание материала исследования",
          "Описание методики исследования (пошаговое)": "Описание методики исследования (пошаговое)",
          "Библиографический список": "Библиографический список",
          "Требования к оформлению научной статьи": "Требования к оформлению научной статьи",
          "Электронный вариант статьи в отдельном файле в формате RTF или Документ Word 1997-2003, например, Иванов_Ульяновск_статья. Объем текста – не менее 4 страниц, не более 9 страниц.": "Электронный вариант статьи в отдельном файле в формате RTF или Документ Word 1997-2003, например, Иванов_Ульяновск_статья. Объем текста – не менее 4 страниц, не более 9 страниц.",
          "Параметры страницы": "Параметры страницы",
          ": текст в формате RTF или Документ Word 1997-2003, поля: слева, справа, снизу и сверху – 2 см. Страницы не проставлять.": ": текст в формате RTF или Документ Word 1997-2003, поля: слева, справа, снизу и сверху – 2 см. Страницы не проставлять.",
          "Шрифт": "Шрифт",
          ": Times New Roman, размер – 14, междустрочный интервал – 1,5, абзац (первая строка) – 1,25; отступ: слева, справа – 0; интервалы: до, после – 0. Вручную (с помощью пробела) абзацы не оформлять! Выравнивание текста – по ширине.": ": Times New Roman, размер – 14, междустрочный интервал – 1,5, абзац (первая строка) – 1,25; отступ: слева, справа – 0; интервалы: до, после – 0. Вручную (с помощью пробела) абзацы не оформлять! Выравнивание текста – по ширине.",
          "Оформление текста": "Оформление текста",
          ": Фамилия и инициалы автора, организация, город, страна указываются перед заглавием статьи полужирным шрифтом, выравнивание текста - справа. Заглавие статьи прописными буквами полужирным шрифтом, выравнивание текста - по центру. Под заглавием через 1 интервал - краткая аннотация статьи (3-8 строк) и ключевые слова (не более 6 слов или словосочетаний). Все данные представляются сначала на русском языке, потом – на английском.": ": Фамилия и инициалы автора, организация, город, страна указываются перед заглавием статьи полужирным шрифтом, выравнивание текста - справа. Заглавие статьи прописными буквами полужирным шрифтом, выравнивание текста - по центру. Под заглавием через 1 интервал - краткая аннотация статьи (3-8 строк) и ключевые слова (не более 6 слов или словосочетаний). Все данные представляются сначала на русском языке, потом – на английском.",
          "Оформление списка источников": "Оформление списка источников",
          ": Список источников оформляется в порядке цитирования в тексте и нумеруется. В список источников включают записи только на ресурсы, которые упомянуты или цитируются в основном тексте статьи с обязательным указанием места и названия издательства, общего количества страниц. Шрифт Times New Roman, размер – 12, междустрочный интервал – 1, абзац (первая строка) – 0. Для связи с текстом статьи порядковый номер библиографической записи и страницу указывают в квадратных скобках: [3, c. 82], автоматических сносок не делать.": ": Список источников оформляется в порядке цитирования в тексте и нумеруется. В список источников включают записи только на ресурсы, которые упомянуты или цитируются в основном тексте статьи с обязательным указанием места и названия издательства, общего количества страниц. Шрифт Times New Roman, размер – 12, междустрочный интервал – 1, абзац (первая строка) – 0. Для связи с текстом статьи порядковый номер библиографической записи и страницу указывают в квадратных скобках: [3, c. 82], автоматических сносок не делать.",
          "Форма регистрации": "Форма регистрации",
          "Участник": "Участник",
          "Фамилия": "Фамилия",
          "Имя": "Имя",
          "Отчество": "Отчество",
          "Ru Фамилия": "Фамилия",
          "Ru Имя": "Имя",
          "Ru Отчество": "Отчество",
          "Дата рождения": "Дата рождения",
          "Добавить участника": "Добавить участника",
          "Место работы / учёбы": "Место работы / учёбы полностью",
          "Должность / факультет, курс": "Должность / факультет, курс",
          "Сведения о научном руководителе": "Сведения о научном руководителе",
          "Место работы": "Место работы",
          "Должность": "Должность",
          "Ученое степень, ученое звание, почетное звание": "Ученое степень, ученое звание, почетное звание",
          "Сведения о проекте": "Сведения о проекте",
          "Тема научно-исследовательской работы": "Тема научно-исследовательской работы",
          "Название направления": "Название направления",
          "Категория участников": "Категория участников",
          "школьник": "школьник",
          "студент": "студент",
          "студент-лингвист": "студент-лингвист",
          "молодой ученый": "молодой ученый",
          "Контактный телефон": "Контактный телефон",
          "Настоящим я даю согласие на обработку моих персональных данных УлГПУ им. И.Н. Ульянова (далее - Университет), в том числе на совершение Администрацией действий, предусмотренных п. 3 ст. 3 Федерального закона от 27.07.2006 года №152-ФЗ \"О персональных данных\"": "Настоящим я даю согласие на обработку моих персональных данных УлГПУ им. И.Н. Ульянова, в том числе на совершение Администрацией действий, предусмотренных п. 3 ст. 3 Федерального закона от 27.07.2006 года №152-ФЗ \"О персональных данных\"",
          "Файл загружен": "Файл загружен",
          "Ошибка добавления файла": "Ошибка добавления файла",
          "Статья успешно загружена": "Статья успешно загружена",
          "Загрузка статьи": "Загрузка статьи",
          "Файл прикреплён": "Файл прикреплён",
          "Прикрепить файл": "Прикрепить файл",
          "Загрузить": "Загрузить",
          "Вы успешно вошли в систему": "Вы успешно вошли в систему",
          "Авторизация": "Авторизация",
          "Пароль": "Пароль",
          "Запомнить меня": "Запомнить меня",
          "Не верный email или пароль": "Не верный email или пароль",
          "Войти": "Войти",
          "Вы успешно зарегистрировались": "Вы успешно зарегистрировались",
          "Ошибка регистрации": "Ошибка регистрации",
          "Аннотация": "Описание проекта",
          "Эксперты": "Эксперты",
          "Список участников допущенных к участию во втором этапе": "Список участников допущенных к участию во втором этапе",
          "Список участников допущенных к участию в третьем этапе": "Список участников допущенных к участию в третьем этапе",
          "Победители VII Международного конкурса научно-исследовательских работ \"Моя интеллект-траектория\"":"Победители VII Международного конкурса научно-исследовательских работ \"Моя интеллект-траектория\"",
          "Кафедра английской филологии МарГУ": "Кафедра английской филологии МарГУ",
          "Российская Федерация, Республика Марий Эл, г. Йошкар-Ола, ул. Кремлевская, 44": "Российская Федерация, Республика Марий Эл, г. Йошкар-Ола, ул. Кремлевская, 44",
          "Личный кабинет": "Личный кабинет",
          "Выход": "Выход",
          "Вход": "Вход",
          "Программа VII международной молодежной научно-практической конференции": "Программа VII международной молодежной научно-практической конференции",
          "Дипломы / Сертификаты / Благодарности": "Дипломы / Сертификаты / Благодарности",
          "Дипломы лауреатов": "Дипломы лауреатов",
          "Сертификаты участников": "Сертификаты участников",
          "Дипломы победителей": "Дипломы победителей",
          "Благодарности руководителям": "Благодарности руководителям",
        }
      }
    },
    fallbackLng: "ru",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;