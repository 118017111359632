import {
     useParams
} from "react-router-dom";
import diploms from './diploms';
import MuiLink from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Trans } from "react-i18next";


export default function DiplomsList(props) {
    let params = useParams();

    let category = diploms.filter((e) => e.cat === params.cat)[0];


    return (
            <Container id="diploms">
                <Box
                    sx={{
                        marginTop: 4,
                        marginBottom: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" >
                        <Trans>
                            {category.name}
                        </Trans>
                    </Typography>
            </Box>
            <ul>
                {category.list.map((user, idx) =>
                    <li><MuiLink href={`/diploms/${user.file}`}>{user.fio}</MuiLink></li>
                )}
            </ul>
        </Container>
    );


}
