import React, { useState, useEffect} from 'react';
import {
     useParams
} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import UploadIcon from '@mui/icons-material/Upload';
import CheckIcon from '@mui/icons-material/Check';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Trans } from "react-i18next";
import axios from 'axios';



export default function Upload(props) {

    const t = props.t;

    let params = useParams();
    const [isSelected, setIsSelected] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [file, setFile] = useState();

    const selectFile = async (files) => {
        setFile(files[0]);
        setIsSelected(true);
    }

    const getStatus = async () => {
        try {
            let res = await axios.get(`/json/isUploaded?id=${params.id}&rnd=${Math.random()}`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                setUploaded(res.data.isUploaded);
            } else {
            }
        } catch {
            console.log("error with status request")
        }
    }

    const uploadFile = async (files) => {
        try {
            let formData = new FormData();
            formData.append("file", file);
            formData.append("id", params.id);
            let res = await axios.post(`/json/uploadFile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar(t("Файл загружен"), "success");
                setUploaded(true);
            } else {
                props.showSnackbar(t("Ошибка добавления файла"), "error");
                console.log("error")
            }
        } catch (e) {
            props.showSnackbar(t("Ошибка добавления файла"), "error");
            console.log(e)
        }
    }

    useEffect(() => {
        getStatus();
    }, []);

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {uploaded ?
                    <>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <CheckIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            <Trans>
                                Статья успешно загружена
                            </Trans>
                        </Typography>
                    </>
                    :
                    <>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <UploadIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            <Trans>
                                Загрузка статьи
                            </Trans>
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Button variant={"outlined"} component={"label"} startIcon={<AttachFileIcon />}>
                                        {isSelected ?
                                            <Trans>Файл прикреплён</Trans>
                                            :
                                            <Trans>Прикрепить файл</Trans>
                                        }
                                        <input type={"file"} hidden accept="*/*" onChange={(e) => selectFile(e.target.files)} />
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={!isSelected}
                                        onClick={() => uploadFile()}
                                    >
                                        <Trans>
                                            Загрузить
                                        </Trans>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
            </Box>
        </Container>
    );
}