import React, { useState} from 'react';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import {
     useParams
} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import AddCardIcon from '@mui/icons-material/AddCard';
import fields from "./fields";

export default function AddStaff(props) {

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        axios.post(`/json/dashboard/addUserWithRole`, {
            email: email,
            lastName: lastName,
            firstName: firstName,
            middleName: middleName,
            phone: phone,
            city: city,
            workplace: workplace,
            position: position,
            role: params.role,
            field: field
        }).then((res) => {
            if(res.data.status === "ok"){
                props.showSnackbar("Сотрудник добавлен", "success");
                setEmail("");
                setLastName("");
                setFirstName('');
                setMiddleName('');
                setPhone('');
                setCity('');
                setWorkPlace('');
                setPosition('');
                setField("");
            }else{
                props.showSnackbar("Ошибка", "error");
            }
        }).catch((err) => {
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        })

    };

    let params = useParams();

    const [email, setEmail] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [workplace, setWorkPlace] = useState("");
    const [position, setPosition] = useState("");
    const [field, setField] = useState("");

    return (
        <>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <AddCardIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                        Добавить {params.role == "curator" ? "Куратора" : "Эксперта"}
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} >
                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Фамилия"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                id="firstName"
                                label="Имя"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                id="middleName"
                                label="Отчество"
                                name="middleName"
                                value={middleName}
                                onChange={(e) => {
                                    setMiddleName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                id="phone"
                                label="Телефон"
                                name="phone"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                id="city"
                                label="Город"
                                name="city"
                                value={city}
                                onChange={(e) => {
                                    setCity(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                id="workplace"
                                label="Место работы"
                                name="workplace"
                                value={workplace}
                                onChange={(e) => {
                                    setWorkPlace(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                fullWidth
                                id="position"
                                label="Должность"
                                name="position"
                                value={position}
                                onChange={(e) => {
                                    setPosition(e.target.value);
                                }}
                            />
                        </Grid>
                    {params.role == 'curator' &&
                        <Grid item xs={6} >
                            <TextField
                                required
                                fullWidth
                                id="field"
                                label="Направление"
                                name="field"
                                value={field}
                                select
                                onChange={(e) => {
                                    setField(e.target.value);
                                }}
                            >
                                {fields.map((key) => (
                                    <MenuItem key={key} value={key}>{key}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
}
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={(lastName.length < 3) || !email.includes('@') || (params.role=='curator' && field=='')}
                            >
                            Добавить
                            </Button>
                        </Grid>
                    </Grid>
            </Box>
        </>
    );
}
