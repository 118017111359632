import React, {useEffect, useState, useRef} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import statusesExpert from './statusesExpert';
import statuses from './statuses';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import fields from "./fields";
import categories from "./categories";
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select'
import FilesList from './FilesList';

export default function AdminProjectCard(props) {

    const [prj, setProject] = useState(props.project);
    const [expert, setExpert] = useState("");
    const [status, setStatus] = useState("");

    const handleChange = (event) => {
        console.log(event.target);
        const new_project = Object.assign({},prj);
        new_project[event.target.name] = event.target.value;
        console.log(new_project);
        setProject(new_project);
    };

    const handleChangeExpertRating = (event) => {
        console.log(event.target);
        const id = event.target.name.split('-');
        const new_project = Object.assign({},prj);
        new_project[new_project.status][id[0]].rating[id[1]] = new_project.status == "etap1" ? (event.target.checked?1:0) : event.target.value;
        console.log(new_project);
        setProject(new_project);
    };

    const handleChangeExpertComment = (event) => {
        const new_project = Object.assign({},prj);
        new_project[new_project.status][event.target.name].comment = event.target.value;
        setProject(new_project);
    };

    const saveProject = () => {
        const new_project = Object.assign({},prj);
        new_project.new_status = status;
        axios.post(`/json/dashboard/saveProject?id=${new_project._id}`, new_project).then((res) => {
            if(res.data.status === "ok"){
                props.showSnackbar("Проект сохранён", "success");
            }else{
                props.showSnackbar("Ошибка", "error");
            }
        }).catch((err) => {
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        })

    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${props.project._id}-content`}
                id={`${props.project._id}-header`}
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>[{prj.category}] {prj.upi != '' ? prj.upi : prj._id}</Typography>
                <Typography sx={{ width: '33%', flexShrink: 0 }}>{prj.email} <IconButton edge="end" aria-label="copy" onClick={() => navigator.clipboard.writeText(prj.email) } >
                    <ContentCopyIcon />
                    </IconButton> ({prj.phone})</Typography>
                <Typography sx={{ color: 'text.secondary' }}>{statuses[prj.status]} {prj.secondAttempt && !prj.status.includes('Fix') ? '(повторно)' :''} {props.type=="all" && `#${!!prj.place?prj.place: '-'} [${prj.sum}]`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Участники
                        </Typography>
                        <Typography >
                            {prj.participants.map(u => `${u.ru_lastName}${u.lastName != null ? '(' + u.lastName + ')' : ''} ${u.ru_firstName}${u.firstName != null ? '(' + u.firstName + ')' : ''} ${u.ru_middleName} (${(new Date(u.birthsday)).toLocaleDateString('ru')}) ${u.workplace}/${u.position}`).join("; ")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Руководитель
                        </Typography>
                        <Typography >
                            {prj.qualification}, {prj.lastName} {prj.firstName} {prj.middleName}, {prj.workplace}/{prj.position}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Тема научно-исследовательской работы
                        </Typography>
                        <Typography >
                            {prj.topic}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography >
                            <TextField
                                fullWidth
                                id="upi"
                                label="Шифр"
                                name="upi"
                                value={prj.upi}
                                onChange={handleChange}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Категория
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="field-label"
                                name="category"
                                value={prj.category}
                                onChange={handleChange}
                            >
                                {categories.map((f) =>
                                    <FormControlLabel key={f} value={f} control={<Radio />} label={f} />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Направление
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="field-label"
                                name="field"
                                value={prj.field}
                                onChange={handleChange}
                            >
                                {fields.map((f) =>
                                    <FormControlLabel key={f} value={f} control={<Radio />} label={f} />
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" gutterBottom>
                            Файлы
                        </Typography>
                        <Typography >
                            <FilesList showSnackbar={props.showSnackbar} project={prj} addFile={(field, file) => {
                                const new_project = Object.assign({}, prj);
                                new_project[field] = file;
                                setProject(new_project);
                            }}
                            removeFile={(field) => {
                                const new_project = Object.assign({}, prj);
                                new_project[field] = null;
                                setProject(new_project);
                            }}
                             />
                        </Typography>
                    </Grid>
                    {(props.type == "needExpert" || props.type =="all") && !!prj[prj.status] && <>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle2" gutterBottom>
                                Назначение экспертов: {statuses[prj.status]}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={Object.keys(props.expertsRv)}
                                        fullWidth
                                        onChange={(e, v) => setExpert(props.expertsRv[v])}
                                        renderInput={(params) => <TextField {...params} label="Эксперт" />}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        disabled={expert == ""}
                                        endIcon={<AddIcon />}
                                        onClick={() => {
                                            const new_project = Object.assign({}, prj);
                                            const experts = new_project[prj.status].map(e => e.expert);
                                            if (!experts.includes(expert)) {
                                                new_project[prj.status].push({ expert: expert, rating: [], comment: "", status: props.type =="all" ? "accepted": "expert" })
                                            }
                                            setProject(new_project);
                                        }}>
                                        Добавить
                                    </Button>
                                </Grid>
                            </Grid>
                            <List >
                                {prj[prj.status].map(e =>
                                    <ListItem
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => {
                                                const new_project = Object.assign({}, prj);
                                                new_project[prj.status] = new_project[prj.status].filter(exp => exp.expert != e.expert);
                                                setProject(new_project);
                                            }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={props.experts[e.expert]}
                                            secondary={e.expert}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                        </>
                    }
                    {props.type == "all" && !!prj && prj.status.replace(/^\D+/g, '') > 1 &&
                    [...Array(prj.status == "etap3" &&  prj.etap3.length > 0  ?  3 : prj.status.replace(/^\D+/g, '')-1).keys()].map((i) => 
                        <Grid item xs={4}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`${props.project._id}-${i}-marks`}
                                id={`${props.project._id}-${i}-marks-header`}
                            >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>Оценки этап {i+1}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item xs={12}>
                                    {prj["etap"+(i+1)].map((exp, edx) => <>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Экспертиза от {exp.expert}
                                        </Typography>
                                        <FormGroup>
                                            {!!props.criteria && props.criteria["etap"+(i+1)].map((e, idx) =>
                                                i == 0 ? <FormControlLabel control={<Checkbox name={`${edx}-${idx}`} checked={!!exp.rating[idx] ? exp.rating[idx] : false} onChange={handleChangeExpertRating} />} label={e} /> :
                                                    <TextField
                                                        sx={{ mb: 1 }}
                                                        name={`${edx}-${idx}`}
                                                        value={!!exp.rating[idx] ? exp.rating[idx] : 0}
                                                        label={e}
                                                        onChange={handleChangeExpertRating}
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                            )}
                                        </FormGroup>
                                        <TextField
                                            id="comment"
                                            name={edx}
                                            label="Комментарий"
                                            multiline
                                            minRows={10}
                                            fullWidth
                                            value={exp.comment}
                                            onChange={handleChangeExpertComment}
                                        />
                                    </>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        </Grid>
                    )
                    }
                    {props.type == "all" && !!prj[prj.status]  &&  
                        <Grid item xs={12}>
                            {prj[prj.status].map((exp, edx) => <>
                                <Typography variant="subtitle2" gutterBottom>
                                    Экспертиза от {exp.expert}
                                </Typography>
                                <FormGroup>
                                    {!!props.criteria && props.criteria[prj.status].map((e, idx) =>
                                        prj.status == "etap1" ? <FormControlLabel control={<Checkbox name={`${edx}-${idx}`} checked={!!exp.rating[idx] ? exp.rating[idx] : false} onChange={handleChangeExpertRating} />} label={e} /> :
                                            <TextField
                                                sx={{ mb: 1 }}
                                                name={`${edx}-${idx}`}
                                                value={!!exp.rating[idx] ? exp.rating[idx] : 0}
                                                label={e}
                                                onChange={handleChangeExpertRating}
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                    )}
                                </FormGroup>
                                <TextField
                                    id="comment"
                                    name={edx}
                                    label="Комментарий"
                                    multiline
                                    minRows={10}
                                    fullWidth
                                    value={exp.comment}
                                    onChange={handleChangeExpertComment}
                                />
                            </>
                            )}
                        </Grid>
                    }
                    {props.type == "needCurator" && !!prj[prj.status] &&
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Оценки экспертов {prj[prj.status].filter(e => e.status != "expert").length}/{prj[prj.status].length}: {statuses[prj.status]}
                            </Typography>
                            {prj[prj.status].filter(e => e.status == "curator").map((e, edx) =>
                                <Accordion key={e.expert}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${props.project._id}-${e.expert}-content`}
                                        id={`${props.project._id}-${e.expert}-header`}
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>{e.expert}</Typography>
                                        <Typography >{props.experts[e.expert]}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup>
                                            {!!prj && !!prj.status && !!props.criteria && props.criteria[prj.status] && props.criteria[prj.status].map((c, idx) =>
                                                prj.status == "etap1" ? <FormControlLabel control={<Checkbox name={`${edx}-${idx}`} checked={!!e.rating[idx] ? e.rating[idx] == 1 : false} onChange={handleChangeExpertRating} />} label={c} /> :
                                                    <TextField
                                                        sx={{ mb: 1 }}
                                                        name={`${edx}-${idx}`}
                                                        value={!!e.rating[idx] ? e.rating[idx] : 0}
                                                        label={c}
                                                        onChange={handleChangeExpertRating}
                                                        type="number"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                            )}
                                        </FormGroup>
                                        <TextField
                                            id="comment"
                                            name={edx}
                                            label="Комментарий"
                                            multiline
                                            minRows={10}
                                            fullWidth
                                            value={e.comment}
                                            onChange={handleChangeExpertComment}
                                        />
                                        <Button
                                            variant="contained"
                                            endIcon={<CheckIcon />}
                                            onClick={() => {
                                                const new_project = Object.assign({}, prj);
                                                new_project[new_project.status][edx].status = "accepted";
                                                setProject(new_project);
                                                if (prj.status == "etap1" && prj[prj.status].reduce((pv, cv) => pv += cv.rating.reduce((pv, cv) => pv += cv, 0), 0) == prj[prj.status].length * props.criteria[prj.status].length) {
                                                    setStatus("etap2");
                                                }
                                            }}>
                                            Одобрить
                                        </Button>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {prj.status != "etap3" && (props.type == "needCurator" || props.type == "all") && !!prj[prj.status] && prj[prj.status].length == prj[prj.status].filter((e) => e.status == "accepted").length &&
                            <FormControl sx={{ m: 1, minWidth: 160 }}>
                                <InputLabel id="demo-simple-select-label">Новый статус</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    label="Новый статус"
                                    autoWidth
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    {(prj.status != "etap1" || prj[prj.status].reduce((pv, cv) => pv += cv.rating.reduce((pv, cv) => pv += cv, 0), 0) != prj[prj.status].length * props.criteria[prj.status].length) &&
                                        <MenuItem value={`${prj.status}ToFix`}>На доработку</MenuItem>}
                                    {(prj.status == "etap2" || (prj.status == "etap1" && prj[prj.status].reduce((pv, cv) => pv += cv.rating.reduce((pv, cv) => pv += cv, 0), 0) == prj[prj.status].length * props.criteria[prj.status].length)) &&
                                        <MenuItem value={prj.status == 'etap1' ? "etap2" : "etap3"}>Следующий этап</MenuItem>}
                                </Select>
                            </FormControl>
                        }
                        <Button
                            variant="contained"
                            endIcon={<SaveIcon />}
                            onClick={saveProject}
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
