import React, {useEffect, useState, useRef} from 'react';
import {
     useParams
} from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import { Trans } from "react-i18next";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import axios from 'axios';
import PhoneMask from "./PhoneMask";
import EmailMask from "./EmailMask";
import fields from "./fields";
import categories from "./categories";
import UploadIcon from '@mui/icons-material/Upload';



export default function Registration(props) {

  const t = props.t;
  const i18n = props.i18n;

    let params = useParams();

    const uploadAFile = async (files, field) => {
        setFile(files[0]);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
       const uchastniki = [...Array(participants).keys()].map((idx)=> {
            return {
                ru_lastName: data.get('ru_lastName'+idx),
                ru_firstName: data.get('ru_firstName'+idx),
                ru_middleName: data.get('ru_middleName'+idx),
                lastName: data.get('lastName'+idx),
                firstName: data.get('firstName'+idx),
                birthsday: birthsday[idx],
                workplace: data.get('workplace'+idx),
                position: data.get('position'+idx),
            }
        });

        try {
            let formData = new FormData();
            formData.append("file", file);
            formData.append("token", token);
            formData.append("lang", i18n.language);
            formData.append("participants", JSON.stringify(uchastniki));
            ['lastName','firstName', 'middleName', 'workplace', 'position', 'qualification', 'topic', 'field', 'category', 'phone', 'email']
            .map(e=> formData.append(e, data.get(e)));
            let res = await axios.post(!!props.edit ? `/json/dashboard/saveProject?id=${params.id}` : `/json/registerProject`, formData);
            /*
            let res = await axios.post(!!props.edit ? `/json/dashboard/saveProject?id=${params.id}` : `/json/registerProject`, {
                participants: uchastniki,
                lastName: data.get('lastName'),
                firstName: data.get('firstName'),
                middleName: data.get('middleName'),
                workplace: data.get('workplace'),
                position: data.get('position'),
                qualification: data.get('qualification'),
                topic: data.get('topic'),
		annotation: data.get('annotation'),
                field: data.get('field'),
                category: data.get('category'),
                phone: data.get('phone'),
                email: data.get('email'),
                token: token,
                lang: i18n.language,
            });
            */
            if (res.data.status === "ok") {
                props.showSnackbar(t("Вы успешно зарегистрировались"), "success");
                if(!!!props.edit){
                    setIsRegistered(true);
                }
                //setEditLink()
                //setUploadLink()
            } else {
                props.showSnackbar(t("Ошибка регистрации"), "error");
            }
        } catch (err) {
            props.showSnackbar(t("Ошибка регистрации"), "error");
            console.log(err);
        }
    };

    const handleChange = (event) => {
        console.log(event.target);
        const new_project = Object.assign({},project);
        if(/\d+$/.test(event.target.name)){
            console.log(event.target.name);
            let match = /(\D+)(\d+)$/.exec(event.target.name);
            console.log(match);
            new_project.participants[match[2]][match[1]] = event.target.value;
        }else{
            new_project[event.target.name] = event.target.value;
        }
        console.log(new_project);
        setProject(new_project);
    };
    const [project, setProject] = useState({
        participants: [...Array(3).keys()].map((idx)=> {
            return {
            ru_lastName: "",
            ru_firstName: "",
            ru_middleName: "",
            lastName: "",
            firstName: "",
            workplace: "",
            position: "",
            };
        }),
        field: "Лингвистика",
        category: "школьник",
        firstName: "",
        lastName: "",
        middleName: "",
        qualification: "",
        workplace: "",
        position: "",
        topic: "",
        annotation: "",
    });
    const [isAccept, setIsAccept] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [phone, setPhone] = useState("");
    const [participants, setParticipants] = useState(1);
    const [email, setEmail] = useState("");
    const [birthsday, setBirthsday] = useState([new Date(), new Date(), new Date(), new Date()]);
    const [token, setToken] = useState(null);
    const [file, setFile] = useState(null);
    const captchaRef = useRef(null);

    const load= async (id) => {
        try {
            let res = await axios.get(`/json/dashboard/getProject?id=${params.id}&rnd=${Math.random()}`);
            console.log(res.data);
            if (!!res.data.status && res.data.status === "ok") {
                setProject(res.data.project);
                setParticipants(res.data.project.participants.length);
                setPhone(res.data.project.phone);
                setEmail(res.data.project.email);
                setBirthsday(res.data.project.participants.map((el) => el.birthsday));
            } else {
                props.showSnackbar("Ошибка загрузки проекта", "error");
                console.log("error")
            }
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if(props.edit){
            load(params.id);
        }
        if (token)
            console.log(`hCaptcha Token: ${token}`);
    },[token]);

    const onLoad = () => {
    // this reaches out to the hCaptcha JS API and runs the
    // execute function on it. you can use other functions as
    // documented here:
    // https://docs.hcaptcha.com/configuration#jsapi
        captchaRef.current.execute();
    };


    return (
        <Container component="main" maxWidth="sm">
<LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language === "ru"? ruLocale : enLocale}>
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {isRegistered ? 
                    <>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <CheckIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        <Trans>
                            Вы успешно зарегистрировались
                        </Trans>
                    </Typography>
                    </>
                    :
                    <>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {!!props.edit ? 
                    <>
                                        Редактирование заявки
                    </>
                    :
                    <Trans>
                            Форма регистрации
                    </Trans>
}
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        {[...Array(participants).keys()].map((idx) => (
                            <React.Fragment key={idx}>
                                <Grid item xs={12} sm={12}>
                                    <FormLabel><Trans>Участник</Trans> {idx+1}</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`ru_lastName${idx}`}
                                        label={t("Ru Фамилия")}
                                        name={`ru_lastName${idx}`}
                                        autoComplete="family-name"
                                        value={project.participants[idx].ru_lastName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        autoComplete="given-name"
                                        name={`ru_firstName${idx}`}
                                        required
                                        fullWidth
                                        id={`ru_firstName${idx}`}
                                        label={t("Ru Имя")}
                                        value={project.participants[idx].ru_firstName}
                                        onChange={handleChange}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`ru_middleName${idx}`}
                                        label={t("Ru Отчество")}
                                        name={`ru_middleName${idx}`}
                                        autoComplete="middle-name"
                                        value={project.participants[idx].ru_middleName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DesktopDatePicker
                                        disableFuture
                                        label={t("Дата рождения")}
                                        id={`birthsday${idx}`}
                                        name={`birthsday${idx}`}
                                        onChange={(newValue) => setBirthsday(birthsday.map((el,idx2)=> idx2===idx ? newValue: el))}
                                        value={birthsday[idx]}
                                        required
                                        fullWidth
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                                {i18n.language === "en" && <>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            autoComplete="given-name"
                                            name={`firstName${idx}`}
                                            required
                                            fullWidth
                                            id={`firstName${idx}`}
                                            label="Name"
                                            autoFocus
                                            value={project.participants[idx].firstName}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            required
                                            fullWidth
                                            id={`lastName${idx}`}
                                            label="Surname"
                                            name={`lastName${idx}`}
                                            autoComplete="family-name"
                                            value={project.participants[idx].lastName}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </>}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`workplace${idx}`}
                                        label={t("Место работы / учёбы")}
                                        name={`workplace${idx}`}
                                        autoComplete="workplace"
                                        value={project.participants[idx].workplace}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`position${idx}`}
                                        label={t("Должность / факультет, курс")}
                                        name={`position${idx}`}
                                        autoComplete="position"
                                        value={project.participants[idx].position}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </React.Fragment>
                        ))}
                        {participants < 4 &&
                            <Grid item xs={12} sm={12}>
                                <Button variant="outlined" startIcon={<AddIcon />} onClick={()=> setParticipants(participants+1)}>
                                    <Trans>Добавить участника</Trans>
                                </Button>
                            </Grid>
                            }
                            <Grid item xs={12} sm={12}>
                                <FormLabel><Trans>Сведения о научном руководителе</Trans></FormLabel>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id={`lastName}`}
                                        label={t("Фамилия")}
                                        name={`lastName`}
                                        autoComplete="family-name"
                                        value={project.lastName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        autoComplete="given-name"
                                        name={`firstName`}
                                        required
                                        fullWidth
                                        id={`firstName`}
                                        label={t("Имя")}
                                        autoFocus
                                        value={project.firstName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`middleName`}
                                        label={t("Отчество")}
                                        name={`middleName`}
                                        autoComplete="middle-name"
                                        value={project.middleName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`workplace`}
                                        label={t("Место работы")}
                                        name={`workplace`}
                                        autoComplete="workplace"
                                        value={project.workplace}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`position`}
                                        label={t("Должность")}
                                        name={`position`}
                                        autoComplete="position"
                                        value={project.position}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id={`qualification`}
                                        label={t("Ученое степень, ученое звание, почетное звание")}
                                        name={`qualification`}
                                        autoComplete="qualification"
                                        value={project.qualification}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            <Grid item xs={12} >
                                <FormLabel><Trans>Сведения о проекте</Trans></FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="topic"
                                    label={t("Тема научно-исследовательской работы")}
                                    name="topic"
                                            value={project.topic}
                                            onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                        <Button variant="outlined" startIcon={<UploadIcon />} component={"label"}>
                                            {file == null ? <Trans>Аннотация</Trans> : file.name}
                                            <input type={"file"} hidden accept="*/*" onChange={(env) => uploadAFile(env.target.files)} />
                                        </Button>
                                    </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="field-label"><Trans>Название направления</Trans></FormLabel>
                                    <RadioGroup
                                        aria-labelledby="field-label"
                                        name="field"
                                                value={project.field}
                                                onChange={handleChange}
                                    >
                                        {fields.map((f) => 
                                            <FormControlLabel value={f} control={<Radio />} label={t(f)} />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="category-label"><Trans>Категория участников</Trans></FormLabel>
                                    <RadioGroup
                                        aria-labelledby="category-label"
                                        name="category"
                                                value={project.category}
                                                onChange={handleChange}
                                    >
                                        {categories.map(c => <FormControlLabel key={c} value={c} control={<Radio />} label={t(c)} />)}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    label={t("Контактный телефон")}
                                    autoComplete="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    InputProps={{
                                        inputComponent: PhoneMask
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        inputComponent: EmailMask
                                    }}
                                />
                            </Grid>
                            {!!!props.edit &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label={t(`Настоящим я даю согласие на обработку моих персональных данных УлГПУ им. И.Н. Ульянова (далее - Университет), в том числе на совершение Администрацией действий, предусмотренных п. 3 ст. 3 Федерального закона от 27.07.2006 года №152-ФЗ "О персональных данных"`)}
                                    onChange={(e) => {
                                        setIsAccept(e.target.checked)
                                    }}
                                />
                            </Grid>
                             }
                            {!!!props.edit &&
                            <Grid item xs={12}>
                                <HCaptcha
                                    sitekey="4bdcd371-b6f2-421c-bc67-90255262144e"
                                    onLoad={onLoad}
                                    onVerify={setToken}
                                    ref={captchaRef}
                                    languageOverride={i18n.language}
                                />
                            </Grid>
                                    }
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!!!props.edit && (!isAccept || !email.includes('@') || file==null)}
                        >
                            {!!props.edit ? 
                            <>Сохранить</>
                            :
                            <Trans>
                                Регистрация
                            </Trans>
                         }
                        </Button>
                    </Box>
                    </>
}
                </Box>
            </LocalizationProvider>
        </Container>
    );
}
