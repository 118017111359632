import React, {useEffect, useState} from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


export default function ProjectsList(props) {
    useEffect(() => {
        getList();
    },[]);


    const getList = async () => {
        try {
            let res = await axios.get(`/json/dashboard/getSettings?rnd=${Math.random()}`);
            if (!!res.data.status && res.data.status === "ok") {
                setList(res.data.rows);
                return res.data.rows;
            } else {
                console.log("error")
                props.showSnackbar("Ошибка запроса списка", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const save = async (name, value) => {
        try {
            let res = await axios.post(`/json/dashboard/setSetting`, {
		    "name": name,
		    "value": value
	    });
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Сохранено", "success");
                getList()
            } else {
                console.log("error")
                props.showSnackbar("Ошибка доступа", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const addSetting = async () => {
        try {
            let res = await axios.get(`/json/dashboard/addSetting?name=${name}&value=${value}`);
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Сохранено", "success");
                getList()
            } else {
                console.log("error")
                props.showSnackbar("Ошибка доступа", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }


    const [list, setList] = useState([]);
    const [name, setName] = useState("");
    const [value, setValue] = useState("");

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4} >
                    <TextField
                        required
                        fullWidth
                        id="name"
                        label="Настройка"
                        name="name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={4} >
                    <TextField
                        required
                        fullWidth
                        id="value"
                        label="Значение"
                        name="value"
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={4} >
                    <Button
                        fullWidth
                        variant="contained"
                        disabled={name.length < 3 || value.length < 3}
                        onClick={addSetting}
                    >
                        Добавить
                    </Button>
                </Grid>
            </Grid>
            <TableContainer sx={{ width: '100%' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Настройка
                            </TableCell>
                            <TableCell>
                                Значение
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((cell) =>
                            <TableRow>
                                <TableCell ><b>{cell.name}</b></TableCell>
                                <TableCell >
                                    <TextField
                                        required
                                        fullWidth
                                        label="Значение"
                                        value={cell.value}
                                        onChange={(e) => {
                                            var lst = [...list];
                                            const idx = lst.findIndex(e => e.name == cell.name);
                                            lst[idx].value = e.target.value;
                                            setList(lst);
                                        }}
                                    />
                                </TableCell>
                                <TableCell >
                                    <Tooltip title="Сохранить">
                                        <IconButton aria-label={"Сохранить"} color={"primary"} onClick={() => save(cell.name, cell.value)}>
                                            <SaveIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

