module.exports = [
    {
        cat: "l",
        name: "Дипломы лауреатов",
        list: [
            {
                fio: "Васягиной Юлии Андреевне",
                file: "l1.pdf"
            },
            {
                fio: "Стебляшкиной Полине Сергеевне",
                file: "l2.pdf"
            },
            {
                fio: "Луциковой Веронике Владимировне",
                file: "l3.pdf"
            },
            {
                fio: "Ерш Анжелике Игоревне",
                file: "l4.pdf"
            },
            {
                fio: "Петрушину Дмитрию Сергеевичу",
                file: "l5.pdf"
            },
            {
                fio: "Савадяркиной Анастасии Дмитриевне",
                file: "l6.pdf"
            },
            {
                fio: "Игнатьевой Анне Станиславовне",
                file: "l7.pdf"
            },
            {
                fio: "Разумковой Татьяне Сергеевне",
                file: "l8.pdf"
            },
            {
                fio: "Сухановой Анне Олеговне",
                file: "l9.pdf"
            },
            {
                fio: "Чивильдеевой Татьяне Ивановне",
                file: "l10.pdf"
            },
        ]
    },
    {
        cat: "s",
        name: "Сертификаты участников",
        list: [
            {
                fio: "Зарипова Алиса Руслановна",
                file: "s1.pdf",
            },
            {
                fio: "Денисова Любовь Андреевна",
                file: "s2.pdf",
            },
            {
                fio: "Яночко Елизавета Сергеевна",
                file: "s3.pdf",
            },
            {
                fio: "Хамдеева Миляуша Сибгатьевна",
                file: "s4.pdf",
            },
            {
                fio: "Мещерякова Софья Игоревна",
                file: "s5.pdf",
            },
            {
                fio: "Комарова Екатерина Дмитриевна",
                file: "s6.pdf",
            },
            {
                fio: "Витвинова Арина Сергеевна",
                file: "s7.pdf",
            },
            {
                fio: "Разводовская Маргарита Олеговна",
                file: "s8.pdf",
            },
            {
                fio: "Адрусевич Анна Александровна",
                file: "s9.pdf",
            },
            {
                fio: "Емельянова Арина Александровна",
                file: "s10.pdf",
            },
            {
                fio: "Жаровская Дарья Николаевна",
                file: "s11.pdf",
            },
            {
                fio: "Сесина Анастасия Дмитриевна",
                file: "s12.pdf",
            },
            {
                fio: "Филиппова Анастасия Александровна",
                file: "s13.pdf",
            },
            {
                fio: "Рид Айвин Эмилия",
                file: "s14.pdf",
            },
            {
                fio: "Дерябина Алена Сергеевна",
                file: "s15.pdf",
            },
            {
                fio: "Лукьянова Светлана Ярославовна",
                file: "s16.pdf",
            },
            {
                fio: "Садакова Анна Игоревна",
                file: "s17.pdf",
            },
            {
                fio: "Рустамов Агагюль Рамизович",
                file: "s18.pdf",
            },
            {
                fio: "Булычева Елизавета Алексеевна",
                file: "s19.pdf",
            },
            {
                fio: "Гребнева Дарья Евгеньевна",
                file: "s20.pdf",
            },
            {
                fio: "Овлякулова Айгозель Мердановна",
                file: "s21.pdf",
            },
            {
                fio: "Тойчибекова Жанар Талғатқызы",
                file: "s22.pdf",
            },
            {
                fio: "Елинова Александра Валерьевна",
                file: "s23.pdf",
            },
            {
                fio: "Амелин Евгений Алексеевич",
                file: "s24.pdf",
            },
            {
                fio: "Быкова Алина Евгеньевна",
                file: "s25.pdf",
            },
            {
                fio: "Варухина Наталья Максимовна",
                file: "s26.pdf",
            },
            {
                fio: "Гаврилова Марина Сергеевна",
                file: "s27.pdf",
            },
            {
                fio: "Кулыгина Анастасия Валерьевна",
                file: "s28.pdf",
            },
            {
                fio: "Максадова Махектач Максадовна",
                file: "s29.pdf",
            },
            {
                fio: "Максимочкина Анастасия Андреевна",
                file: "s30.pdf",
            },
            {
                fio: "Москвина Екатерина Александровна",
                file: "s31.pdf",
            },
            {
                fio: "Новикова Виктория Романовна",
                file: "s32.pdf",
            },
            {
                fio: "Стёганцева Любовь Андреевна",
                file: "s33.pdf",
            },
            {
                fio: "Чернядьева Алина Сергеевна",
                file: "s34.pdf",
            },
            {
                fio: "Шувалов Кузьма Валентинович",
                file: "s35.pdf",
            }
        ]
    },
    {
        cat: "p",
        name: "Дипломы победителей",
        list: [
            {
                fio: "Какашвили Софье Юрьевне",
                file: "p1.pdf",
            },
            {
                fio: "Юськаевой Эльмире Ильясовне",
                file: "p2.pdf",
            },
            {
                fio: "Пельдяковой Милене Алексеевне",
                file: "p3.pdf",
            },
            {
                fio: "Есенковой Дарье Андреевне",
                file: "p4.pdf",
            },
            {
                fio: "Игониной Анжелике Витальевне",
                file: "p5.pdf",
            },
            {
                fio: "Желемеевой Валерии Ивановне",
                file: "p6.pdf",
            },
            {
                fio: "Кулевой Полине Александровне",
                file: "p7.pdf",
            },
            {
                fio: "Меньшиковой Екатерине Викторовне",
                file: "p8.pdf",
            },
            {
                fio: "Родионовой Анастасии Вячеславовне",
                file: "p9.pdf",
            },
            {
                fio: "Скворцову Ярославу Алексеевичу",
                file: "p10.pdf",
            },
            {
                fio: "Рыбке Софье Борисовне",
                file: "p11.pdf",
            },
            {
                fio: "Булановой Анастасии Дмитриевне",
                file: "p12.pdf",
            },
            {
                fio: "Василенко Юлии Владимировне",
                file: "p13.pdf",
            },
            {
                fio: "Григорьевой Анне Вячеславовне",
                file: "p14.pdf",
            },
            {
                fio: "Колодий Денису Андреевичу",
                file: "p15.pdf",
            },
            {
                fio: "Монастырской Екатерине Анатольевне",
                file: "p16.pdf",
            },
            {
                fio: "Ройхка Павлу Витальевичу",
                file: "p17.pdf",
            },
            {
                fio: "Талаповой Асель Кайратовне",
                file: "p18.pdf",
            },
            {
                fio: "Ухановой Арине Ивановне",
                file: "p19.pdf",
            },
            {
                fio: "Чертову Ярославу Александровичу",
                file: "p20.pdf",
            },
            {
                fio: "Ширковой Виолетте Сергеевне",
                file: "p21.pdf",
            },
            {
                fio: "Александровой Татьяне Евгеньевне",
                file: "p22.pdf",
            },
            {
                fio: "Дудукаловой Елизавете Дмитриевне",
                file: "p23.pdf",
            },
            {
                fio: "Зацепиловой Анастасии Сергеевне",
                file: "p24.pdf",
            },
            {
                fio: "Полуховой Веронике Алексеевне",
                file: "p25.pdf",
            },
            {
                fio: "Гладких Егору Андреевичу",
                file: "p26.pdf",
            },
            {
                fio: "Карелину Георгию Евгеньевичу",
                file: "p27.pdf",
            },
            {
                fio: "Картавовой Полине Юрьевне",
                file: "p28.pdf",
            },
            {
                fio: "Киларджиевой Софии Борисовне",
                file: "p29.pdf",
            },
            {
                fio: "Королёвой Алисе Андреевне",
                file: "p30.pdf",
            },
            {
                fio: "Крыловой Марии Николаевне",
                file: "p31.pdf",
            },
            {
                fio: "Матуриной Варваре Дмитриевне",
                file: "p32.pdf",
            },
            {
                fio: "Мейхер Алёне Евгеньевне",
                file: "p33.pdf",
            },
            {
                fio: "Михеевой Алине Алексеевне",
                file: "p34.pdf",
            },
        ],
    },
    {
        cat: "b",
        name: "Благодарности руководителям",
        list: [
            {
                fio: "Золотарёвой Татьяне Викторовне",
                file: "b1.pdf",
            },
            {
                fio: "Сусловой Элле Владимировне",
                file: "b2.pdf",
            },
            {
                fio: "Дубровской Татьяне Викторовне",
                file: "b3.pdf",
            },
            {
                fio: "Лобиной Юлии Анатольевне",
                file: "b4.pdf",
            },
            {
                fio: "Захаровой Инне Геннадьевне",
                file: "b5.pdf",
            },
            {
                fio: "Фесенко Ольге Петровне",
                file: "b6.pdf",
            },
            {
                fio: "Фоминой Марине Аркадьевне",
                file: "b7.pdf",
            },
            {
                fio: "Дарановой Дарье Игоревне",
                file: "b8.pdf",
            },
            {
                fio: "Мухиной Юлии Николаевне",
                file: "b9.pdf",
            },
            {
                fio: "Лобиной Юлии Анатольевне",
                file: "b10.pdf",
            },
            {
                fio: "Кацуновой Наталье Николаевне",
                file: "b11.pdf",
            },
            {
                fio: "Сысковой Анастасии Евгеньевне",
                file: "b12.pdf",
            },
            {
                fio: "Моисеенко Анне Валерьевне",
                file: "b13.pdf",
            },
            {
                fio: "Николаевой Ольге Александровне",
                file: "b14.pdf",
            },
            {
                fio: "Адушкиной Евгении Евгеньевне",
                file: "b15.pdf",
            },
            {
                fio: "Рулевской Елене Сергеевне",
                file: "b16.pdf",
            },
            {
                fio: "Балышевой Ксении Александровне",
                file: "b17.pdf",
            },
            {
                fio: "Копытич Ирине Георгиевне",
                file: "b18.pdf",
            },
            {
                fio: "Жучковой Ирине Игоревне",
                file: "b19.pdf",
            },
            {
                fio: "Лисице Инне Валерьевне",
                file: "b20.pdf",
            },
            {
                fio: "Морозкиной Татьяне Владимировне",
                file: "b21.pdf",
            },
            {
                fio: "Стефанович Марии Владимировне",
                file: "b22.pdf",
            },
            {
                fio: "Красновой Татьяне Васильевне",
                file: "b23.pdf",
            },
            {
                fio: "Сысковой Анастасии Евгеньевне",
                file: "b24.pdf",
            },
            {
                fio: "Ивановой Ульяне Юрьевне",
                file: "b25.pdf",
            },
            {
                fio: "Конухиной Марии Дмитриевне",
                file: "b26.pdf",
            },
            {
                fio: "Леон Ольге Вячеславовне",
                file: "b27.pdf",
            },
            {
                fio: "Ореховой Юлии Михайловне",
                file: "b28.pdf",
            },
            {
                fio: "Садыковой Аиде Кенесбековне",
                file: "b29.pdf",
            },
            {
                fio: "Салтыковой Екатерине Алексеевне",
                file: "b30.pdf",
            },
            {
                fio: "Третьяковой Галине Викторовне",
                file: "b31.pdf",
            },
            {
                fio: "Чирковой Марии Алексеевне",
                file: "b32.pdf",
            },
            {
                fio: "Леон Ольге Вячеславовне",
                file: "b33.pdf",
            },
            {
                fio: "Маляновой Виктории Сергеевне",
                file: "b34.pdf",
            },
            {
                fio: "Суховой Наталье Витальевне",
                file: "b35.pdf",
            },
            {
                fio: "Ивановой Ульяне Юрьевне",
                file: "b36.pdf",
            },
            {
                fio: "Кунаковой Кларе Умурзаковне",
                file: "b37.pdf",
            },
            {
                fio: "Максадовой Махектач Максадовне",
                file: "b38.pdf",
            },
            {
                fio: "Романовой Елене Викторовне",
                file: "b39.pdf",
            },
            {
                fio: "Борботько Людмиле Александровне",
                file: "b40.pdf",
            },
            {
                fio: "Бреус Анне Александровне",
                file: "b41.pdf",
            },
            {
                fio: "Шебеко Никите Эдуардовича",
                file: "b42.pdf",
            },
            {
                fio: "Васильеву Льву Геннадьевичу",
                file: "b43.pdf",
            },
            {
                fio: "Ишанкулиевой Огулсоне Мухаммедовне",
                file: "b44.pdf",
            },
            {
                fio: "Гулиянц Светлане Борисовне",
                file: "b45.pdf",
            },
            {
                fio: "Беклемешевой Наталье Николаевне",
                file: "b46.pdf",
            },
            {
                fio: "Гулиянц Анне Борисовне",
                file: "b47.pdf",
            },
            {
                fio: "Гулиянц Анне Борисовне",
                file: "b48.pdf",
            },
            {
                fio: "Кардановой-Бирюковой Ксении Суфьяновне",
                file: "b49.pdf",
            },
            {
                fio: "Кардановой-Бирюковой Ксении Суфьяновне",
                file: "b50.pdf",
            }
        ]
    }

];