import React, {useEffect, useState} from "react";
import axios from "axios";
import Typography from '@mui/material/Typography';
import AdminProjectCard from "./AdminProjectCard";
import ExpertProjectCard from "./ExpertProjectCard";
import fields from "./fields";
import categories from "./categories";

export default function ProjectsList(props) {
    useEffect(() => {
        getList();
        if(props.type != "expert"){
            getExpertList();
        }
        getCriteria();
    },[]);


    const getList = async () => {
        try {
            let res = await axios.get(`/json/dashboard/${props.type == "needExpert" ? "projectsListNeedsExpert" :
            props.type == "needCurator" ? "projectsListNeedsCurator" : "projectsListAll"}?rnd=${Math.random()}`);
            if (!!res.data.status && res.data.status === "ok") {
                setList(res.data.projects);
            } else {
                console.log("error")
                props.showSnackbar("Ошибка запроса списка", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const getCriteria = async () => {
        try {
            let res = await axios.get(`/json/dashboard/criteriaList`);
            if (!!res.data.status && res.data.status === "ok") {
                setCriteria(res.data.criteria);
            } else {
                console.log("error")
                props.showSnackbar("Ошибка запроса критериев", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const getExpertList = async () => {
        try {
            let res = await axios.get(`/json/dashboard/expertList`);
            if (!!res.data.status && res.data.status === "ok") {
                var result = res.data.experts.reduce(function (map, obj) {
                    map[obj.username] = `${obj.lastName} ${obj.firstName} ${obj.middleName}`;
                    return map;
                }, {});
                var resultRv = res.data.experts.reduce(function (map, obj) {
                    map[`${obj.lastName} ${obj.firstName} ${obj.middleName}`] = obj.username;
                    return map;
                }, {});
                setExperts(result);
                setExpertsRv(resultRv);
            } else {
                console.log("error")
                props.showSnackbar("Ошибка запроса списка экспертов", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const [list, setList] = useState([]);
    const [experts, setExperts] = useState({});
    const [expertsRv, setExpertsRv] = useState({});
    const [criteria, setCriteria] = useState({});


    return (
        <>
            {props.type == "all" ? 
            [... props.curator ? [props.field] : fields].map(field => <>
                <Typography variant="h5" gutterBottom align="center">
                    {field}
                </Typography>
                {categories.map(category => <>
                    <Typography variant="h6" gutterBottom>
                        {category}
                    </Typography>
                    {list.filter((e)=> e.field == field && e.category == category).map(prj => 
                        <AdminProjectCard key={prj._id} project={prj} experts={experts} expertsRv={expertsRv} type={props.type} criteria={criteria} showSnackbar={props.showSnackbar} />
                        )}
                </>
                )}
            </>
            )
                : list.map(prj => props.type == "expert" ?
                    <ExpertProjectCard key={prj._id} project={prj} showSnackbar={props.showSnackbar} criteria={criteria} /> :
                    <AdminProjectCard key={prj._id} project={prj} experts={experts} expertsRv={expertsRv} type={props.type} criteria={criteria} showSnackbar={props.showSnackbar} />
                )}
        </>
    )

}

/*
export default function ProjectsList(props) {
    useEffect(() => {
        getList();
    },[]);


    const getList = async () => {
        try {
            let res = await axios.get(`/json/dashboard/projectsList?type=${props.type}&rnd=${Math.random()}`);
            if (!!res.data.status && res.data.status === "ok") {
                setList(res.data.rows);
                return res.data.rows;
            } else {
                console.log("error")
                props.showSnackbar("Ошибка запроса списка", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }

    const changeStatus = async (id, status) => {
        try {
            let res = await axios.get(`/json/dashboard/changeStatus?id=${id}&status=${status}`);
            if (!!res.data.status && res.data.status === "ok") {
                props.showSnackbar("Статус изменён", "success");
                getList()
            } else {
                console.log("error")
                props.showSnackbar("Ошибка доступа", "error");
            }
        }catch (e) {
            console.log(e)
        }
    }


    const columns = React.useMemo(
        () => [
            {
                Header: 'ФИО участников',
                accessor: 'fio',
            },
            {
                Header: 'Руководитель',
                accessor: 'supervisor',
            },
            {
                Header: 'Категория',
                accessor: 'category',
            },
            {
                Header: 'Направление',
                accessor: 'field',
            },
            {
                Header: 'Тема',
                accessor: 'topic',
            },
            {
                Header: 'Файл',
                accessor: 'hasFile',
                Cell: ({cell}) => (
                    <>
                    {cell.row.values.hasFile ? "Да" : "Нет"}
                    </>
                )
            },
            {
                Header: 'Управление',
                accessor: 'id',
                disableFilters: true,
                Cell: ({cell}) => (
                    <>
                        <Tooltip title="Редактировать">
                            <IconButton aria-label={"Редактировать"} color={"primary"} component={"a"} href={`/dashboard/edit/${cell.row.values.id}`} >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        {props.type === "added" || props.type === "toedit" || props.type === "rejected" ? <>
                            {props.type !== "toedit" &&
                                <Tooltip title="На доработку">
                                    <IconButton aria-label={"На доработку"} color={"warning"} onClick={() => { changeStatus(cell.row.values.id, "toedit") }} >
                                        <BuildIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {props.type !== "rejected" &&
                            <Tooltip title="Отклонён">
                                <IconButton aria-label={"Отклонён"} color={"error"} onClick={() => { changeStatus(cell.row.values.id, "rejected") }} >
                                    <ThumbDownIcon />
                                </IconButton>
                            </Tooltip>
                            }
                            {props.type !== "accepted" &&
                            <Tooltip title="Принят">
                                <IconButton aria-label={"Принят"} color={"success"} onClick={() => { changeStatus(cell.row.values.id, "accepted") }} >
                                    <ThumbUpIcon />
                                </IconButton>
                            </Tooltip>
                            }
                        </>
                        :
                        props.type === "accepted" ? <IconButton aria-label={"Загрузить статью"} color={"primary"} component={'a'} href={`/json/dashboard/getFile?id=${cell.row.values.id}`} disabled={!cell.row.values.hasFile}>
                                <DownloadIcon />
                        </IconButton>
                        :
                        <></>
                        }
                    </>
                )
            },
        ],
        []
    )

    const [list, setList] = useState([]);
    const data = React.useMemo(() => list, [list]);

    return (
        <Table columns={columns} data={data} />
    )
}

*/