import React, {useEffect, useState, useRef} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import statuses from './statuses';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FilesList from './FilesList';

export default function ExpertProjectCard(props) {
  const [prj, setProject] = useState(props.project);
  const [rating, setRating] = useState([]);
  const [comment, setComment] = useState("");

    const saveProject = () => {
        axios.post(`/json/dashboard/saveExpert?id=${prj._id}`, {rating: rating, comment: comment}).then((res) => {
            if(res.data.status === "ok"){
                props.showSnackbar("Экспертиза отправленна. Спасибо!", "success");
            }else{
                props.showSnackbar("Ошибка", "error");
            }
        }).catch((err) => {
            props.showSnackbar("Ошибка", "error");
            console.log(err);
        })

    }

    const handleChange = (event) => {
        console.log(event.target.value);
        const new_rating = [...rating];
        new_rating[event.target.name] = prj.status=="etap1" ? event.target.checked : event.target.value;
        console.log(rating);
        setRating(new_rating);
    };


  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${props.project._id}-content`}
        id={`${props.project._id}-header`}
      >
        <Typography sx={{ width: '33%', flexShrink: 0 }}>[{prj.category}] {prj.upi != '' ? prj.upi : prj._id}</Typography>
        <Typography sx={{ color: 'text.secondary' }}>{statuses[prj.status]}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Тема научно-исследовательской работы
            </Typography>
            <Typography >
              {prj.topic}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              {prj.category} / Направление
            </Typography>
                {prj.field}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" gutterBottom>
              Файлы
            </Typography>
            <Typography >
              <FilesList expert showSnackbar={props.showSnackbar} project={prj} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Экспертиза
            </Typography>
            <FormGroup>
              {!!props.criteria && props.criteria[prj.status].map((e,idx) =>
                prj.status == "etap1" ? <FormControlLabel control={<Checkbox name={idx} checked={!!rating[idx] ? rating[idx]: false } onChange={handleChange} />} label={e} /> :
                  <TextField
                    sx={{mb:1}}
                    name={idx}
                    value={!!rating[idx] ? rating[idx] : 0}
                    label={e}
                    onChange={handleChange}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
              )}
            </FormGroup>
            <TextField
              id="comment"
              name="comment"
              label="Комментарий"
              multiline
              minRows={10}
              fullWidth
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              endIcon={<SaveIcon />}
              onClick={saveProject}
            >
              Отправить заключение
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
