import './App.css';
import React, {useState, useEffect} from 'react';
import { useTranslation, Trans } from "react-i18next";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import CssBaseline from '@mui/material/CssBaseline';
import Snackbar from '@mui/material/Snackbar';
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import {
    Link, Route, Routes,
    Navigate,useNavigate
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';

import './i18n';

import Home from  "./Home.js";
import Registration from "./Registration.js";
import Upload from "./Upload.js";
import Login from "./Login.js";
import Dashboard from "./Dashboard";
import AdminDashboard from "./AdminDashboard";
import Forms from "./Forms";
import MailTemplatesList from "./MailTemplatesList";
import MailTemplate from "./MailTemplate";
import StaffList from "./StaffList";
import AddStaff from "./AddStaff";
import SettingsList from "./SettingsList";
import UserDashboard from './UserDashboard';
import ExpertDashboard from './ExpertDashboard.js';
import DiplomsList from './DiplomsList.js';


const theme= createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#355264',
        },
        secondary: {
            main: '#e4541d',
        },
    },
});


function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
  };

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("");
  const [userRole, setUserRole] = React.useState("");
  const [field, setField] = React.useState("");
  const [isLoginIn, setIsLoginIn] = useState(false);
  
  const showSnackbar = (message, severity = "") => {
    setSnackMessage(message);
    setSnackSeverity(severity)
    setSnackOpen(true);
  };

let navigate = useNavigate();

 const logout = () => {
        axios.get(`/json/logout`).then((res) => {
            setIsLoginIn(false);
            showSnackbar("Вы вышли из системы")
            navigate("/");
        }).catch((err) => {
            console.log(err);
        })
    }

  const getStatus = async () => {
    try {
      let res = await axios.get(`/json/status?rnd=${Math.random()}`);
      console.log(res.data);
      if (!!res.data.status && res.data.status === "ok") {
        setUserRole(res.data.userRole);
        setField(res.data.field);
        await new Promise(resolve => setTimeout(resolve, 100));
        setIsLoginIn(true);
      } else {
        setIsLoginIn(false)
        setUserRole("");
      }
    } catch {
      setIsLoginIn(false)
      console.log("error with status request")
    }
  }

  useEffect(() => {
        getStatus();
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
        <CssBaseline />
          <Box sx={{}}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6"  sx={{ flexGrow: 1 }} component={Link} to={"/"} style={{ color: "#fff", textDecoration: "none" }}>
                  <Trans>
                    Моя интеллект-траектория
                  </Trans>
                </Typography>
              <Button aria-label="language" color="inherit" onClick={()=>changeLanguage(i18n.language === "ru" ? "en" : "ru")}>
                {i18n.language === "ru" ?
                "EN"
                :
                "RU"
                }
              </Button>
              {isLoginIn ? <>
                <Button variant="outlined" startIcon={<PersonIcon />} component={Link} to={ `/dashboard/admin`} style={{ color: "#fff", textDecoration: "none" }}>
                  <Trans>
                    Личный кабинет
                  </Trans>
                </Button>
                <Button variant="outlined" startIcon={<LogoutIcon />} onClick={() => {logout();}} style={{ color: "#fff", textDecoration: "none" }}>
                  <Trans>
                    Выход
                  </Trans>
                </Button>
                </>
                :
                <Button variant="outlined" startIcon={<LoginIcon />} component={Link} to={"/login"} style={{ color: "#fff", textDecoration: "none" }}>
                  <Trans>
                    Вход
                  </Trans>
                </Button>
              }
              </Toolbar>
            </AppBar>
          </Box>
        <Routes>
          <Route path={"/"} element={<Home showSnackbar={showSnackbar}  t={t} i18n={i18n} />} />
          <Route path={"/registration"} element={<Registration showSnackbar={showSnackbar} t={t} i18n={i18n} />} />
          <Route path={"/upload/:id"} element={<Upload showSnackbar={showSnackbar} t={t} />} />
          <Route path={"/diploms/:cat"} element={<DiplomsList />} />
          <Route path={"/login"} element={<Login showSnackbar={showSnackbar} t={t} getStatus={getStatus} />} />
          <Route path={"/dashboard/admin"} element={
              userRole == "admin" || userRole == "curator" ?
                <AdminDashboard showSnackbar={showSnackbar} curator={userRole=="curator"} field={field} /> :
              userRole == "expert" ?
                <ExpertDashboard showSnackbar={showSnackbar} /> :
                <UserDashboard showSnackbar={showSnackbar} t={t} i18n={i18n} />
            } >
            </Route>
          <Route path={"/dashboard"} element={isLoginIn ? <Dashboard showSnackbar={showSnackbar} /> : <Navigate to={`/login`} />} >
            <Route path={"mailTemplates"} element={<MailTemplatesList showSnackbar={showSnackbar} />} />
            <Route path={"settings"} element={<SettingsList showSnackbar={showSnackbar} />} ></Route>
            <Route path={"edit/:id"} element={<Registration edit showSnackbar={showSnackbar} t={t} i18n={i18n} />} ></Route>
            <Route path={"staff/:role"} element={<StaffList showSnackbar={showSnackbar} />} ></Route>
          </Route>
          <Route path={"/forms"} element={isLoginIn ? <Forms /> : <Navigate to={`/login`} />} >
            <Route path={"mailTemplate/:id"} element={<MailTemplate showSnackbar={showSnackbar} edit />} />
            <Route path={"addStaff/:role"} element={<AddStaff showSnackbar={showSnackbar} />} />
            <Route path={"addMailTemplate"} element={<MailTemplate showSnackbar={showSnackbar} />} />
          </Route>
        </Routes>
        <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose}>
          {snackSeverity !== "" ?
            <Alert onClose={handleSnackClose} severity={snackSeverity} sx={{ width: '100%' }}>
              {snackMessage}
            </Alert>
            :
            <p>{snackMessage}</p>
          }
        </Snackbar>
        <Box
          component="footer"
          sx={{
            py: 2,
            px: 2,
            mt: 'auto',
            textAlign: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
          <Container >
            <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12} sm={5}>
                <Typography variant="body1">
                  <Trans>
                    Моя интеллект-траектория
                  </Trans>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {'Copyright © '}
                  <MuiLink color="inherit" href={"https://ulspu.ru"}>
                    <Trans>
                      УлГПУ им. И.Н. Ульянова
                    </Trans>
                  </MuiLink>{' '}
                  {new Date().getFullYear()}
                  {'.'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <img src={"/logo192.png"} style={{ height: 80 }} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;